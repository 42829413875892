import { createStore, applyMiddleware, compose } from 'redux';
import { createBrowserHistory } from 'history';
import { routerMiddleware } from 'connected-react-router';
import reducers from './reducers';
import epics from './epics';
import { createEpicMiddleware } from 'redux-observable';

declare const window: any;

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const history = createBrowserHistory({
  basename: '/'
});

const epicMiddleware = createEpicMiddleware();

const enhancer = process.env.NODE_ENV === 'production'
? applyMiddleware(routerMiddleware(history), epicMiddleware)
: composeEnhancers(
    applyMiddleware(routerMiddleware(history), epicMiddleware)
  );

const configureStore = (() => {
  const store = createStore(reducers(history), enhancer);

  epicMiddleware.run(epics);

  return store;
})();

export default configureStore;
