import React from 'react';
import { makeStyles, Table, TableHead, TableRow, TableCell, TableBody } from '@material-ui/core';
import { PaymentHistory } from '../../../../utils/interface/PaymentHistory';
import { decimalPipe } from '../../../../utils/function/pipe/decimalPipe';
import moment from 'moment';

const useStyles = makeStyles({
  table: {
    width: '100%'
  },
  th: {
    color: '#ffffff',
    fontSize: 13,
    backgroundColor: '#585858',
    fontWeight: 600,
    border: '1px solid #707070'
  },
  td: {
    color: '#000000',
    fontSize: 13,
    border: '1px solid #707070'
  }
});

interface Props {
  histories: PaymentHistory[];
}

const PaymentHistoryTable: React.FC<Props> = (props) => {
  const classes = useStyles();

  return (
    <>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell size='small' align='center' className={ classes.th }>日付</TableCell>
            <TableCell size='small' align='center' className={ classes.th }>決済方法</TableCell>
            <TableCell size='small' align='center' className={ classes.th }>金額</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.histories.map(history => (
            <TableRow key={ history.id }>
              <TableCell size='small' align='center' className={ classes.td }>
                { moment(history.billing_date).format('YYYY/MM/DD') }
              </TableCell>
              <TableCell size='small' className={ classes.td }>{ history.paymethod_name }</TableCell>
              <TableCell size='small' align='right' className={ classes.td }>{ decimalPipe(history.price) }円</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </>
  );
}

export default PaymentHistoryTable;