import React from 'react';
import PageTitle from '../../../common/PageTitle/PageTitle';
import { MachineMovieData } from '../../../../utils/interface/MachineMovieData';
import { makeStyles, List, ListItem } from '@material-ui/core';
import MachineMovie from '../../../common/MachineMovie/MachineMovie';

const useStyles = makeStyles({
  content: {
    padding: '30px 16px'
  },
  listItem: {
    padding: 0,

    '& + &': {
      marginTop: 30
    }
  }
})

interface Props {}

const HowToUseMachine: React.FC<Props> = (props) => {
  const classes = useStyles();

  const datas: MachineMovieData[] = [
    {videoId: 'HCyBPPD95vw', name: 'トレッドミル'},
    {videoId: 'edZjGVxIINo', name: 'アシスト・ディップ・チン'},
    {videoId: 'sAeDw6xhFFw', name: 'ペクトラル・フライ / リア・デルトイド'},
    {videoId: 'MjVDrYPD7Rs', name: 'ショルダー・プレス'},
    {videoId: '0GU9_kT6tBY', name: 'シーテッド・ロー'},
    {videoId: '_qwLdYywo_A', name: 'シーテッド・レッグ・カール'},
    {videoId: 'MpEydcQ1oDw', name: 'レッグ・エクステンション'},
    {videoId: 'yyla8aCTMWo', name: 'シーテッド・レッグ・プレス'},
    {videoId: 'NAIEnMjN-6w', name: 'ラット・プルダウン'},
    {videoId: 'ElDIvayj3oI', name: 'トーソ・ローテーション'},
    {videoId: 'o_0WrejFbAw', name: 'アブドミナル'},
  ];

  return (
    <>
      <PageTitle>マシンの利用方法</PageTitle>
      <div className={ classes.content }>
        <List disablePadding>
          {datas.map(data => (
            <ListItem key={ data.videoId } className={ classes.listItem }>
              <MachineMovie data={ data } />
            </ListItem>
          ))}
        </List>
      </div>
    </>
  );
}

export default HowToUseMachine;