import { ReduxState } from '../../reducers';
import { createSelector } from 'reselect';
import { DirectMessage } from '../../../utils/interface/DirectMessage';

const directMessageEntitiesSelector = (state: ReduxState) => state.entities.directMessages.entities;
const entriesSelector = (state: ReduxState, key: string) => state.entries[key]
  ? state.entries[key].entries
  : [];

const directMessagesSelector = createSelector(
  [directMessageEntitiesSelector, entriesSelector],
  (directMessageEntities, entries) => {
    const messages: DirectMessage[] = [];

    entries.forEach(messageId => {
      if (directMessageEntities[messageId]) {
        messages.push({
          ...directMessageEntities[messageId]
        });
      }
    });

    return messages;
  }
);

export default directMessagesSelector;