import { reducerWithInitialState } from 'typescript-fsa-reducers';
import * as sessionActions from '../actions/SessionAction';

export interface SessionState {
  userId?: number;
  unread_direct_messages_count: number;
}

const initialState: SessionState = {
  unread_direct_messages_count: 0
};

const sessionReducer = reducerWithInitialState(initialState)

// 認証資格の取得成功
.case(sessionActions.storeAuthUserId, (state, payload) => ({
  ...state,
  userId: payload
}))

// 未読件数を取得
.case(sessionActions.fetchUnreadCount.done, (state, payload) => ({
  ...state,
  unread_direct_messages_count: payload.result
}))

export default sessionReducer;
