import { combineEpics } from 'redux-observable';
import sessionEpic from './session';
import newsEpic from './news';
import usageSituationEpic from './usageSituation';
import usageHistoryEpic from './usageHistory';
import paymentHistoryEpic from './paymentHistory';
import directMessageEpic from './directMessage';

const rootEpic = combineEpics(
  sessionEpic,
  newsEpic,
  directMessageEpic,
  usageSituationEpic,
  usageHistoryEpic,
  paymentHistoryEpic,
);

export default rootEpic;