import React from 'react';
import { Route, RouteProps, Redirect } from 'react-router';
import { ReduxState } from '../../../redux/reducers';
import { connect } from 'react-redux';
import { History } from 'history';

interface GuestRouteProps extends RouteProps {
  isAuthenticated: boolean;
  redirectTo?: History.LocationDescriptor<any>;
}

const GuestRoute: React.FC<GuestRouteProps> = ({component: Component, render, isAuthenticated, redirectTo, ...rest}) => {
  return render ? (
    <Route {...rest} render={ !isAuthenticated
      ? render
      : () => <Redirect to='/' />
    }/>
  ) : (
    <Route {...rest} render={props => !isAuthenticated && Component !== undefined
      ? <Component {...props} />
      : <Redirect to='/' />
    } />
  );
}

const mapStateToProps = (state: ReduxState) => ({
  isAuthenticated: state.session.userId !== undefined,
});

export default connect(mapStateToProps)(GuestRoute);
