import actionCreatorFactory from 'typescript-fsa';
import { SessionActionType } from '../action-types/SessionActionType';
import { AxiosError } from 'axios';
import { AuthCredentials } from '../../utils/interface/AuthCredentials';

const actionCreator = actionCreatorFactory('Session');

// 認証資格の取得成功
export const fetchAuthCredentialsSuccess = actionCreator<AuthCredentials>(SessionActionType.FETCH_AUTH_CREDENTIALS_SUCCESSFULL);

// 認証資格の取得失敗
export const fetchAuthCredentialsFailed = actionCreator<AxiosError>(SessionActionType.FETCH_AUTH_CREDENTIALS_FAILED);

// 認証ユーザーIDを保存
export const storeAuthUserId = actionCreator<number>(SessionActionType.AUTH_USER_ID_STORED);

// 未読件数を取得
export const fetchUnreadCount = actionCreator.async<{}, number, AxiosError>(SessionActionType.FETCH_UNREAD_DIRECT_MESSAGE_COUNT);

// 未読件数の取得をキャンセル
export const cancelFetchingUnreadCount = actionCreator(SessionActionType.FETCH_UNREAD_DIRECT_MESSAGE_COUNT_CANCELLED);

// ログアウト
export const logout = actionCreator.async<{}, void, AxiosError>(SessionActionType.LOGOUT);
