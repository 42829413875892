import { Entity } from '.';
import { reducerWithInitialState } from 'typescript-fsa-reducers';
import * as entityActions from '../../actions/EntityAction';
import { updateEntityState } from '../../../utils/function/updateEntityState';
import { NewsEntity } from '../../../utils/interface/Entity/NewsEntity';

const initialState: Entity<NewsEntity> = {
  entities: [],
  fetchStatus: {},
  error: {}
};

const newsEntitiesReducer = reducerWithInitialState(initialState)

// エンティティを更新
.case(entityActions.update, (state, payload) => updateEntityState(state, payload.news))

export default newsEntitiesReducer;
