import actionCreatorFactory from 'typescript-fsa';
import { PaymentHistoryActionType as ActionType } from '../../redux/action-types/PaymentHistoryActionType';
import { AxiosError } from 'axios';

const actionCreator = actionCreatorFactory('PaymentHistory');

// 支払い履歴を取得
export const fetchPaymentHistories = actionCreator.async<{}, number[], AxiosError>(ActionType.FETCH_PAYMENT_HISTORIES);

// 支払い履歴の取得をキャンセル
export const cancelFetchingPaymentHistories = actionCreator(ActionType.FETCH_PAYMENT_HISTORIES_CANCELLED);