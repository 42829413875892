import React from 'react';
import PageTitle from '../../../common/PageTitle/PageTitle';
import Headline from '../../../common/Headline/Headline';
import { makeStyles, Button, Typography } from '@material-ui/core';
import { FetchStatus } from '../../../../utils/type/FetchStatus';
import { PaymentHistory } from '../../../../utils/interface/PaymentHistory';
import { ReduxState } from '../../../../redux/reducers';
import { paymentHistoryStateKey } from '../../../../redux/reducers/entries/paymentHistory';
import initialFetchStatusSelector from '../../../../redux/selectors/entry/initialFetchStatusSelector';
import paymentHistoriesSelector from '../../../../redux/selectors/paymentHistory/paymentHistoriesSelector';
import { Dispatch } from 'redux';
import * as paymentHistoriesActions from '../../../../redux/actions/PaymentHistoryAction';
import { connect } from 'react-redux';
import PaymentHistoryTable from '../PaymentHistoryTable/PaymentHistoryTable';
import Progress from '../../../common/Progress/Progress';
import { Link } from 'react-router-dom';
import WarningMessage from '../../../common/WarningMessage/WarningMessage';
import EmptyMessage from '../../../common/EmptyMessage/EmptyMessage';

const useStyles = makeStyles({
  content: {
    padding: '30px 16px'
  },
  subTitle: {
    fontWeight: 600,
    marginBottom: 5
  }
})

interface Props {
  fetchStatus: FetchStatus;
  paymentHistories: PaymentHistory[];
  loadHistories: () => void;
  cancelLoading: () => void;
}

const PaymentRoot: React.FC<Props> = (props) => {
  const classes = useStyles();

  const handleReload = () => {
    props.loadHistories();
  }

  React.useEffect(() => {
    props.loadHistories();

    return () => {
      props.cancelLoading();
    }
  }, []);

  return (
    <>
      <PageTitle>お支払い</PageTitle>
      {props.fetchStatus === 'loading' && (<Progress />)}

      {props.fetchStatus === 'loaded' && (
        <>
          {/* <div className={ classes.content }>
            <Headline>お支払い方法</Headline>
            <Button variant='contained' component={ Link } to='/'>
              クレジットカードの変更
            </Button>
          </div> */}

          <div className={ classes.content }>
            <Headline>お支払い履歴</Headline>
            {props.paymentHistories.length > 0
              ? (
                <>
                  <Typography variant='subtitle1' className={ classes.subTitle }>過去24回分</Typography>
                  <PaymentHistoryTable histories={ props.paymentHistories } />
                </>
              )
              : (
                <EmptyMessage>お支払い履歴はありません</EmptyMessage>
              )
            }
            
          </div>
        </>
      )}

      {props.fetchStatus === 'failed' && (
        <div className={ classes.content }>
          <WarningMessage withButton onClick={ handleReload }/>
        </div>
      )}
    </>
  );
}

const mapStateToProps = (state: ReduxState) => ({
  fetchStatus: initialFetchStatusSelector(state, paymentHistoryStateKey),
  paymentHistories: paymentHistoriesSelector(state, paymentHistoryStateKey),
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
  loadHistories: () => dispatch(paymentHistoriesActions.fetchPaymentHistories.started({})),
  cancelLoading: () => dispatch(paymentHistoriesActions.cancelFetchingPaymentHistories())
});

export default connect(mapStateToProps, mapDispatchToProps)(PaymentRoot);