import React from 'react';
import { ListItem, ListItemText, ListItemIcon, makeStyles } from '@material-ui/core';
import { Link } from 'react-router-dom';

const useStyles = makeStyles({
  root: {
    color: 'inherit'
  },
  icon: {
    minWidth: 40
  }
});

interface Props {
  icon?: JSX.Element;
  text: string;
  to?: string;
  onClick?: () => void;
}

const MenuItem: React.FC<Props> = (props) => {
  const classes = useStyles();

  const handleClick = () => {
    if (props.onClick) {
      props.onClick();
    }
  }

  const children: JSX.Element = (
    <>
      {props.icon && (<ListItemIcon className={ classes.icon }>{ props.icon }</ListItemIcon>)}
      <ListItemText>{ props.text }</ListItemText>
    </>
  );

  return (
    <>
      {props.to 
        ? (
          <ListItem className={ classes.root } component={ Link } to={ props.to } onClick={ handleClick }>
            { children }
          </ListItem>
        )
        : (
          <ListItem className={ classes.root } onClick={ handleClick }>
            { children }
          </ListItem>
        )
      }
    </>
  );
}

export default MenuItem;