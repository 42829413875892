import React from 'react';
import PageTitle from '../../../common/PageTitle/PageTitle';
import { List, ListItem, ListItemText, makeStyles } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { PrivateRouteComponentProps } from '../../../../containers/common/PrivateRoute/PrivateRoute';
import { isPaidy } from '../../../../utils/function/isPaidy';

const useStyles = makeStyles({
  listItem: {
    color: 'inherit'
  }
});

interface Props extends PrivateRouteComponentProps {}

const Settings: React.FC<Props> = (props) => {
  const classes = useStyles();

  return (
    <>
      <PageTitle>設定</PageTitle>
      <List>
        {!isPaidy(props.authUser.paymethod.id) && (
          <ListItem divider component={ Link } to='/settings/credit_card' className={ classes.listItem }>
            <ListItemText>クレジットカード情報の変更</ListItemText>
          </ListItem>
        )}
        <ListItem divider component={ Link } to='/settings/email' className={ classes.listItem }>
          <ListItemText>メールアドレスの変更</ListItemText>
        </ListItem>
        <ListItem divider component={ Link } to='/settings/password' className={ classes.listItem }>
          <ListItemText>パスワードの変更</ListItemText>
        </ListItem>
      </List>
    </>
  );
}

export default Settings;