import { createSelector } from 'reselect';
import { ReduxState } from '../../reducers';

const usersEntitiesSelector = (state: ReduxState) => state.entities.users.entities;
const authUserIdSelector = (state: ReduxState) => state.session.userId;

const authUserSelector = createSelector(
  [usersEntitiesSelector, authUserIdSelector],
  (userEntities, authUserId) => {
    if (authUserId !== undefined && userEntities[authUserId]) {
      return userEntities[authUserId];
    }

    return null;
  }
);

export default authUserSelector;