import React from 'react';
import { makeStyles, InputBase, Button, CircularProgress } from '@material-ui/core';
import PageTitle from '../../../common/PageTitle/PageTitle';
import FormItem from '../../../common/FormItem/FormItem';
import { ValidationError } from '../../../../utils/interface/ValidationError';
import { AxiosError } from 'axios';
import isValidationError from '../../../../utils/function/isValidationError';
import { ReduxState } from '../../../../redux/reducers';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import request from '../../../../utils/function/request';
import { finalize } from 'rxjs/operators';
import { EmailAddress } from '../../../../utils/interface/EmailAddress';
import Progress from '../../../common/Progress/Progress';
import * as sessionActions from '../../../../redux/actions/SessionAction';
import * as toastActions from '../../../../redux/actions/ToastAction';
import isAuthenticationError from '../../../../utils/function/isAuthenticationError';

const useStyles = makeStyles({
  form: {
    padding: '30px 20px'
  },
  formItem: {
    '& + &': {
      marginTop: 25
    }
  },
  label: {
    display: 'inline-block',
    color: '#4d4d4d',
    fontSize: 13,
    marginBottom: 3
  },
  input: {
    height: 36,
    borderRadius: 3,
    border: '1px solid #bdbdbd',
    fontSize: 16,
    boxSizing: 'border-box',
    backgroundColor: '#ffffff',
    outline: 'none',

    '& input, & select': {
      paddingLeft: 8,
    }
  },
  buttonContainer: {
    marginTop: 50
  },
  button: {
    boxShadow: 'none!important',
    height: 40
  },
  resendButton: {
    padding: 0
  }
});

interface FormValue {
  email: string;
  current_email?: string;
  not_verified_email?: string
}

const initialFormValue: FormValue = {
  email: '',
};

interface Props {
  onUpdateSuccess: () => void;
  onUpdateFailed: (error: AxiosError) => void;
  onFatchEmailAddressFailed: (error: AxiosError) => void;
  onResendSuccess: () => void;
  onResendFailed: (error: AxiosError) => void;
}

const EmailSetting: React.FC<Props> = (props) => {
  const classes = useStyles();

  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [isSending, setIsSending] = React.useState<boolean>(false);
  const [formValue, setFormValue] = React.useState<FormValue>(initialFormValue);
  const [validationError, setValidationError] = React.useState<ValidationError>({});

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormValue({
      ...formValue,
      [e.target.name]: e.target.value
    });
  }

  const setEmailAddress = (emails: EmailAddress[]) => {
    // 現在のメールアドレスのIndex番号を取得
    const currentIndex = emails.findIndex(email => email.verified);

    // 確認されていないメールアドレスのIndex番号を取得
    const notVerifiedIndex = emails.findIndex(email => !email.verified);

    setFormValue({
      ...formValue,
      current_email: currentIndex > -1 
        ? emails[currentIndex].email 
        : undefined,
      not_verified_email: notVerifiedIndex > -1 
        ? emails[notVerifiedIndex].email 
        : undefined
    });
  }

  const handleUpdateSuccess = (emails: EmailAddress[]) => {
    setEmailAddress(emails);
    props.onUpdateSuccess();
  }

  const handleUpdateError = (error: AxiosError) => {
    if (error.response && isValidationError(error)) {
      setValidationError(error.response.data.errors);
    }
    props.onUpdateFailed(error);
  }

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsSending(true);
    setValidationError({});

    request<EmailAddress[]>({
      method: 'put',
      url: '/v1/setting/email',
      data: {email: formValue.email},
    }).pipe(
      finalize(() => setIsSending(false))
    ).subscribe(
      res => handleUpdateSuccess(res.data),
      error => handleUpdateError(error)
    )
  }

  const handleResend = () => {
    request({
      method: 'post',
      url: '/v1/setting/email/resend'
    }).subscribe(
      res => props.onResendSuccess(),
      error => props.onResendFailed(error)
    )
  }

  React.useEffect(() => {
    setIsLoading(true);

    const subscription = request<EmailAddress[]>({
      url: '/v1/setting/email',
    }).pipe(
      finalize(() => setIsLoading(false))
    ).subscribe(
      res => setEmailAddress(res.data),
      error => props.onFatchEmailAddressFailed(error)
    );

    return () => {
      if (!subscription.closed) {
        subscription.unsubscribe();
      }
    }
  }, []);

  const isInvalid = !formValue.email ? true : false;

  return (
    <>
      <PageTitle>メールアドレスの変更</PageTitle>

      {isLoading
        ? (<Progress />)
        : (
          <form className={ classes.form } onSubmit={ handleSubmit }>
            {/* 現在のメールアドレス */}
            <FormItem label='現在のメールアドレス'>
              <InputBase fullWidth type='email' name='current_email'
                className={ classes.input }
                value={ formValue.current_email }
                readOnly
              />
            </FormItem>

            {/* 新しいメールアドレス */}
            <FormItem label='新しいメールアドレス' required
              error={ Boolean(validationError.email) }
              helperText={ validationError.email }
            >
              <InputBase fullWidth type='email' name='email'
                className={ classes.input }
                value={ formValue.email }
                onChange={ handleInputChange }
                disabled={ isSending }
                required
                error={ Boolean(validationError.email) }
              />
            </FormItem>

            {formValue.not_verified_email && (
              <div>
                <p>新しいメールアドレス { formValue.not_verified_email } の確認が完了していません。</p>
                <Button type='button' className={ classes.resendButton }
                  color='secondary'
                  onClick={ handleResend }
                >確認メールを再送信</Button>
              </div>
            )}

            <div className={ classes.buttonContainer }>
              <Button type='submit' variant='contained' color='secondary'
                fullWidth
                disabled={ isSending || isInvalid }
                className={ classes.button }
              >
                {isSending ? (<CircularProgress size={ 16 } />) : '変更する'}
              </Button>
            </div>
          </form>
        )
      }
    </>
  );
}

const mapStateToProps = (state: ReduxState) => ({});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  onUpdateSuccess: () => {
    dispatch(toastActions.show({
      message: '新しいメールアドレス宛に確認のメールを送信しました。メール内の指示に従って確認を行なってください。',
      type: 'success'
    }))
  },
  onUpdateFailed: (error: AxiosError) => {
    if (error.response && isAuthenticationError(error)) {
      dispatch(sessionActions.logout.done({params: {}}));
    } else {
      dispatch(toastActions.show({message: 'メールアドレスの変更に失敗しました', type: 'error'}));
    }
  },
  onFatchEmailAddressFailed: (error: AxiosError) => {
    if (error.response && isAuthenticationError(error)) {
      dispatch(sessionActions.logout.done({params: {}}));
    } else {
      dispatch(toastActions.show({message: '現在のメールアドレスを取得できませんでした', type: 'error'}));
    }
  },
  onResendSuccess: () => {
    dispatch(toastActions.show({
      message: '確認のメールを再送信しました。メール内の指示に従って確認を行なってください。',
      type: 'success'
    }))
  },
  onResendFailed: (error: AxiosError) => {
    if (error.response && isAuthenticationError(error)) {
      dispatch(sessionActions.logout.done({params: {}}));
    } else {
      dispatch(toastActions.show({message: '確認メールの再送信に失敗しました', type: 'error'}));
    }
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(EmailSetting);