import React from 'react';
import { makeStyles, InputBase, Button, CircularProgress, NativeSelect, IconButton } from '@material-ui/core';
import { AxiosError } from 'axios';
import { ValidationError } from '../../../../utils/interface/ValidationError';
import isValidationError from '../../../../utils/function/isValidationError';
import request from '../../../../utils/function/request';
import { finalize } from 'rxjs/operators';
import { connect } from 'react-redux';
import * as sessionActions from '../../../../redux/actions/SessionAction';
import * as toastActions from '../../../../redux/actions/ToastAction';
import { routerActions } from 'connected-react-router';
import isAuthenticationError from '../../../../utils/function/isAuthenticationError';
import { ReduxState } from '../../../../redux/reducers';
import { Dispatch } from 'redux';
import PageTitle from '../../../common/PageTitle/PageTitle';
import FormItem from '../../../common/FormItem/FormItem';
import HelpIcon from '@material-ui/icons/Help';
import { PrivateRouteComponentProps } from '../../../../containers/common/PrivateRoute/PrivateRoute';
import { isPaidy } from '../../../../utils/function/isPaidy';

const useStyles = makeStyles({
  form: {
    padding: '30px 20px'
  },
  formItem: {
    '& + &': {
      marginTop: 25
    }
  },
  label: {
    display: 'inline-block',
    color: '#4d4d4d',
    fontSize: 13,
    marginBottom: 3
  },
  input: {
    height: 36,
    borderRadius: 3,
    border: '1px solid #bdbdbd',
    fontSize: 16,
    boxSizing: 'border-box',
    backgroundColor: '#ffffff',
    outline: 'none',
    '& input, & select': {
      paddingLeft: 8,
    }
  },
  slash: {
    display: 'inline-block',
    padding: '0 6px'
  },
  cvc: {
    display: 'flex',
    alignItems: 'center',
  },
  cvcInput: {
    width: 90,
  },
  help: {
    marginLeft: 6,
    color: '#bdbdbd'
  },
  example: {
    marginTop: 25
  },
  exampleImg: {
    width: 'auto',
    maxWidth: '100%'
  },
  buttonContainer: {
    marginTop: 50
  },
  button: {
    boxShadow: 'none!important',
    height: 40
  }
});

interface FormValue {
  number: string;
  month: string;
  year: string;
  cvc: string;
}

const date = new Date();

const initialFormValue: FormValue = {
  number: '',
  month: String(date.getMonth() + 1),
  year: String(date.getFullYear()),
  cvc: ''
};

interface Props extends PrivateRouteComponentProps {
  onSuccess: () => void;
  onFailed: (error: AxiosError) => void;
}

const CreditCardSetting: React.FC<Props> = (props) => {

  React.useEffect(() => {
    if (isPaidy(props.authUser.paymethod.id)) {
      props.history.replace('/settings');
    }
  }, [])

  const classes = useStyles();

  const [isSending, setIsSending] = React.useState<boolean>(false);
  const [formValue, setFormValue] = React.useState<FormValue>(initialFormValue);
  const [validationError, setValidationError] = React.useState<ValidationError>({});
  const [exampleIsActive, setExampleIsActive] = React.useState<boolean>(false);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.name === 'cvc' && e.target.value.length > 4) {
      return;
    }

    setFormValue({
      ...formValue,
      [e.target.name]: e.target.value
    });
  }

  const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setFormValue({
      ...formValue,
      [e.target.name]: e.target.value
    });
  }

  const toggleExample = () => {
    setExampleIsActive(!exampleIsActive);
  }

  const handleError = (error: AxiosError) => {
    if (error.response && isValidationError(error)) {
      setValidationError(error.response.data.errors);
    }
    props.onFailed(error);
  }

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsSending(true);
    setValidationError({});

    request({
      method: 'put',
      url: '/v1/setting/credit_card',
      data: formValue,
    }).pipe(
      finalize(() => setIsSending(false))
    ).subscribe(
      res => props.onSuccess(),
      error => handleError(error)
    )
  }

  const isInvalid = (!formValue.number || !formValue.month || !formValue.year || !formValue.cvc || formValue.cvc.length < 3)
      ? true : false;

  return (
    <>
      <PageTitle>クレジットカードの変更</PageTitle>

      <form className={ classes.form } onSubmit={ handleSubmit }>
        {/* カード番号 */}
        <FormItem label='カード番号' required
          error={ Boolean(validationError.password) }
          helperText={ validationError.password }
        >
          <InputBase fullWidth type='tel' name='number'
            className={ classes.input }
            value={ formValue.number }
            onChange={ handleInputChange }
            disabled={ isSending }
            required
            error={ Boolean(validationError.password) }
          />
        </FormItem>

        {/* 有効期限 */}
        <FormItem label='有効期限（Month / Year）' required
          error={ Boolean(validationError.new_password) }
          helperText={ validationError.new_password }
        >
          <NativeSelect name='month'
            className={ classes.input }
            value={ formValue.month }
            disabled={ isSending }
            input={ <InputBase /> }
            onChange={ handleSelectChange }
            required
            error={ Boolean(validationError.new_password) }
          >
            {[...Array(12)].map((v, i) => (
              <option key={ i } value={ i + 1 } >{ i + 1 }</option>
            ))}
          </NativeSelect>
          <span className={ classes.slash }>/</span>
          <NativeSelect name='year'
            value={ formValue.year }
            disabled={ isSending }
            input={ <InputBase className={ classes.input } /> }
            onChange={ handleSelectChange }
            required
            error={ Boolean(validationError.new_password) }
          >
            {[...Array(11)].map((v, i) => {
              const y: number = date.getFullYear() + i;

              return (
                <option key={ y } value={ y }>{ y }</option>
              )
            })}
          </NativeSelect>
        </FormItem>

        {/* セキュリティコード */}
        <FormItem label='セキュリティコード' required
          error={ Boolean(validationError.new_password_confirmation) }
          helperText={ validationError.new_password_confirmation }
        >
          <div className={ classes.cvc }>
            <InputBase type='tel' name='cvc'
              className={ `${classes.input} ${classes.cvcInput}`  }
              value={ formValue.cvc }
              onChange={ handleInputChange }
              disabled={ isSending }
              required
              error={ Boolean(validationError.new_password_confirmation) }
            />
            <IconButton size='small' className={ classes.help } onClick={ toggleExample }>
              <HelpIcon />
            </IconButton>
          </div>
          {exampleIsActive && (
            <div className={ classes.example }>
              <img src='/img/setting/cvc_example.png' className={ classes.exampleImg } />
            </div>
          )}
        </FormItem>

        <div className={ classes.buttonContainer }>
          <Button type='submit' variant='contained' color='secondary'
            fullWidth
            disabled={ isSending || isInvalid }
            className={ classes.button }
          >
            {isSending ? (<CircularProgress size={ 16 } />) : '変更する'}
          </Button>
        </div>
      </form>
    </>
  );
}

const mapStateToProps = (state: ReduxState) => ({});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  onSuccess: () => {
    dispatch(routerActions.push('/settings'))
    dispatch(toastActions.show({message: 'クレジットカード情報を変更しました', type: 'success'}))
  },
  onFailed: (error: AxiosError) => {
    if (error.response && isAuthenticationError(error)) {
      dispatch(sessionActions.logout.done({params: {}}));
    } else {
      dispatch(toastActions.show({message: 'クレジットカード情報の変更に失敗しました', type: 'error'}));
    }
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(CreditCardSetting);