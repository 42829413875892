import { reducerWithoutInitialState } from 'typescript-fsa-reducers';
import { initialEntryState, EMPTY_STATE_CURSOR, EntryState, EntryReducerState } from '..';
import moment from 'moment';
import * as paymentHistoryActions from '../../../actions/PaymentHistoryAction';

export const paymentHistoryStateKey = 'paymentHistories';

const paymentHistoryReducer = reducerWithoutInitialState<EntryReducerState>()

// 支払い履歴を取得 - 開始
.case(paymentHistoryActions.fetchPaymentHistories.started, (state, payload) => ({
  ...state,
  [paymentHistoryStateKey]: initialEntryState
}))

// 支払い履歴を取得 - 成功
.case(paymentHistoryActions.fetchPaymentHistories.done, (state, {params, result}) => ({
  ...state,
  [paymentHistoryStateKey]: {
    ...state[paymentHistoryStateKey],
    entries: result,
    lastFetchTimestamp: moment(),
    fetchStatus: {
      ...state[paymentHistoryStateKey].fetchStatus,
      [EMPTY_STATE_CURSOR]: 'loaded'
    }
  }
}))

// 支払い履歴を取得 - 失敗
.case(paymentHistoryActions.fetchPaymentHistories.failed, (state, payload) => ({
  ...state,
  [paymentHistoryStateKey]: {
    ...state[paymentHistoryStateKey],
    lastFetchTimestamp: moment(),
    fetchStatus: {
      ...state[paymentHistoryStateKey].fetchStatus,
      [EMPTY_STATE_CURSOR]: 'failed'
    }
  }
}))

export default paymentHistoryReducer;