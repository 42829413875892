import React from 'react';
import { News as INews } from '../../../utils/interface/News';
import { makeStyles } from '@material-ui/core';
import moment from 'moment';

const useStyles = makeStyles({
  root: {
    fontWeight: 600
  },
  category: {
    display: 'inline-block',
    color: '#ffffff',
    fontSize: 12,
    backgroundColor: '#0068B6',
    textAlign: 'center',
    lineHeight: 1,
    padding: '6px 30px',
    borderRadius: 9,
    marginBottom: 10
  },
  body: {
    margin: 0,
  },
  date: {
    // marginBottom: 5
  }
});

interface Props {
  news: INews;
}

const News: React.FC<Props> = (props) => {
  const classes = useStyles();

  return (
    <>
      <div className={ classes.root }>
        <div className={ classes.date }>
          {/* { moment(props.news.date).format('YYYY/MM/DD') } */}
          <span className={ classes.category }>連絡事項</span>
        </div>
        <p className={ classes.body }>{ props.news.body }</p>
      </div>
    </>
  );
}

export default News;