import { ReduxState } from '../../reducers';
import { createSelector } from 'reselect';
import { News } from '../../../utils/interface/News';

const newsEntitiesSelector = (state: ReduxState) => state.entities.news.entities;
const entriesSelector = (state: ReduxState, key: string) => state.entries[key]
  ? state.entries[key].entries
  : [];

const newsListSelector = createSelector(
  [newsEntitiesSelector, entriesSelector],
  (newsEntities, entries) => {
    const newsList: News[] = [];

    entries.forEach(newsId => {
      if (newsEntities[newsId]) {
        newsList.push({
          ...newsEntities[newsId]
        });
      }
    });

    return newsList;
  }
);

export default newsListSelector;