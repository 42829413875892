import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Toast } from '../../../utils/interface/Toast';
import { ToastType } from '../../../utils/type/ToastType';
import theme from '../../../configs/material-ui.config';
import { IconButton } from '@material-ui/core';
import { ReduxState } from '../../../redux/reducers';
import { Dispatch } from 'redux';
import * as toastActions from '../../../redux/actions/ToastAction';
import { connect } from 'react-redux';
import InfoIcon from '@material-ui/icons/Info';
import CheckIcon from '@material-ui/icons/Check';
import WarningIcon from '@material-ui/icons/Warning';
import ErrorIcon from '@material-ui/icons/Error';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles({
  root: {
    fontSize: '0.875rem',
    display: 'flex',
    color: '#ffffff',
    borderRadius: 3,
    boxShadow: '0 3px 6px rgba(0, 0, 0, 0.16)',
  },
  icon: {
    display: 'flex',
    alignItems: 'center',
    minWidth: 50,
    padding: '7px 0 7px 10px'
  },
  message: {
    padding: '7px 5px 7px 0',
  },
  rightControl: {
    marginLeft: 'auto',
    display: 'flex',
    alignItems: 'center',
  },
  close: {
    width: 36,
    height: 36,
    fontSize: '1rem',
    padding: 0,
    color: 'rgba(255, 255, 255, 0.6)'
  },
  success: {
    backgroundColor: '#2BBE85'
  },
  error: {
    backgroundColor: '#CC4343'
  },
  warning: {
    backgroundColor: '#E2B438',
  },
  info: {
    backgroundColor: theme.palette.primary.main
  }
});

interface ToastMessageProps {
  toast: Toast;
  onClose: (id: number) => void;
}

const ToastMessage: React.FC<ToastMessageProps> = (props) => {

  const classes = useStyles();

  const getIcon = (type: ToastType): JSX.Element => {
    switch (type) {
      case 'info':
        return <InfoIcon />;

      case 'success':
        return <CheckIcon />;

      case 'warning':
        return <WarningIcon />;

      case 'error':
        return <ErrorIcon />;
    }
  }

  const handleClose = () => {
    props.onClose(props.toast.id);
  }

  React.useEffect(() => {
    const timer = setTimeout(handleClose, 5000);

    return () => {
      clearTimeout(timer);
    }
  }, []);

  return (
    <>
      <div className={ `${classes.root} ${classes[props.toast.type]}` }>
        <div className={ classes.icon }>
          { getIcon(props.toast.type) }
        </div>
        <p className={ classes.message }>{ props.toast.message }</p>
        <div className={ classes.rightControl }>
          <IconButton disableRipple className={ classes.close } onClick={ handleClose }>
            <CloseIcon />
          </IconButton>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state: ReduxState) => ({});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  onClose: (id: number) => dispatch(toastActions.hide(id))
});

export default connect(mapStateToProps, mapDispatchToProps)(ToastMessage);
