import React from 'react';
import { makeStyles, Typography, Button, CircularProgress } from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/ErrorOutline';
import RefreshIcon from '@material-ui/icons/Refresh';

const useStyles = makeStyles({
  root: {
    color: '#6d6d6d',
    textAlign: 'center',
  },
  icon: {
    fontSize: 22,
  },
  message: {
    fontSize: 16,
    fontWeight: 600,
    textAlign: 'center',
  },
  reload: {
    padding: '30px 0',
  },
  reloadButton: {
    boxShadow: 'none!important',
    height: 40,
    borderRadius: 20,
    fontWeight: 600,
    padding: '6px 20px'
  }
});

interface Props {
  withButton?: boolean;
  onClick?: () => void;
}

const WarningMessage: React.FC<Props> = (props) => {
  const classes = useStyles();

  return (
    <>
      <div className={ classes.root }>
        <ErrorIcon className={ classes.icon } />
        <Typography className={ classes.message }>
          問題が発生しました。
        </Typography>
        {props.withButton && (
          <div className={ classes.reload }>
            <Button variant='contained'
              color='secondary'
              className={ classes.reloadButton }
              onClick={ props.onClick }
            >
              <RefreshIcon style={{ marginRight: '0.375em' }} />再度お試しください
            </Button>
          </div>
        )}
      </div>
    </>
  );
}

export default WarningMessage;