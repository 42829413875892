import reduceReducers from 'reduce-reducers';
import { FetchStatus } from '../../../utils/type/FetchStatus';
import { Moment } from 'moment';
import newsReducer from './news';
import { reducerWithInitialState } from 'typescript-fsa-reducers';
import paymentHistoryReducer from './paymentHistory';
import directMessageReducer from './directMessage';

export const EMPTY_STATE_CURSOR = 'EMPTY_STATE_CURSOR';

export const LATEST_STATE_CURSOR = 'LATEST_STATE_CURSOR';

export interface GapData {
  topEntry: number;
  bottomEntry: number;
  topCursor: string;
  bottomCursor: string;
}

export interface EntryState {
  entries: number[];
  cursor: {
    top: string | null;
    gaps: GapData[];
    bottom: string | null;
  }
  lastFetchTimestamp: Moment | null;
  fetchStatus: {
    [cursor: string]: FetchStatus
  };
}

export const initialEntryState: EntryState = {
  entries: [],
  cursor: {
    top: null,
    gaps: [],
    bottom: null,
  },
  lastFetchTimestamp: null,
  fetchStatus: {
    [EMPTY_STATE_CURSOR]: 'loading',
  }
};

export interface EntryReducerState {
  [key: string]: EntryState;
}

const initialState: EntryReducerState = {};

const entriesReducer = reduceReducers<EntryReducerState>(
  initialState,
  ...[
    newsReducer,
    paymentHistoryReducer,
    directMessageReducer
  ]
);

export default reducerWithInitialState(initialState)
.default((state, action) => entriesReducer(state, action));