import actionCreatorFactory from 'typescript-fsa';
import { LayoutActionType } from '../action-types/LayoutActionType';

const actionCreator = actionCreatorFactory('Layout');

// メニューを開く
export const openMenu = actionCreator(LayoutActionType.MENU_OPENED);

// メニューを閉じる
export const closeMenu = actionCreator(LayoutActionType.MENU_CLOSED);
