import { Epic, combineEpics } from 'redux-observable';
import { filter, switchMap, mergeMap, catchError, takeUntil } from 'rxjs/operators';
import * as entityActions from '../../actions/EntityAction';
import * as paymentHistoryActions from '../../actions/PaymentHistoryAction';
import request, { handleError } from '../../../utils/function/request';
import { normalize } from 'normalizr';
import schemas from '../../schemas';
import { of } from 'rxjs';
import { PaymentHistory } from '../../../utils/interface/PaymentHistory';

/**
 * 支払い履歴（過去24ヶ月分）を取得
 * 
 * @param action$ 
 */
const fetchPaymentHistoriesEpic: Epic = action$ => action$.pipe(
  filter(paymentHistoryActions.fetchPaymentHistories.started.match),
  switchMap(({payload}) => request<PaymentHistory[]>({
    url: '/v1/payment_histories',
  }).pipe(
    mergeMap(res => {
      const normalizedData = normalize(res.data, [schemas.paymentHistory]);

      return of(
        entityActions.update(normalizedData.entities),
        paymentHistoryActions.fetchPaymentHistories.done({params: payload, result: normalizedData.result})
      );
    }),
    catchError(error => handleError(error, paymentHistoryActions.fetchPaymentHistories.failed({
      params: payload,
      error
    }))),
    takeUntil(action$.pipe(
      filter(paymentHistoryActions.cancelFetchingPaymentHistories.match)
    ))
  ))
);

const paymentHistoryEpic = combineEpics(
  fetchPaymentHistoriesEpic
);

export default paymentHistoryEpic;