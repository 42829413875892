import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  root: {
    padding: '10px 0',
    textAlign: 'center'
  },
});

interface Props {}

const EmptyMessage: React.FC<Props> = (props) => {

  const classes = useStyles();

  return (
    <>
      <p className={ classes.root }>{ props.children }</p>
    </>
  );
}

export default EmptyMessage;