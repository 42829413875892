import axios, { AxiosRequestConfig, AxiosError, AxiosResponse } from 'axios';
import Config from '../../configs/app.config';
import { Observable, from, of, empty, merge } from 'rxjs';
import { Action } from 'typescript-fsa';
import { take } from 'rxjs/operators';
import * as sessionActions from '../../redux/actions/SessionAction';
import isAuthenticationError from './isAuthenticationError';

export const client = (() => {
  const _client = axios.create({
    baseURL: Config.API,
    headers: {
      'Access-Control-Allow-Origin': window.location.origin,
      'Access-Control-Allow-Credentials': 'true',
      'X-Requested-With': 'XMLHttpRequest',
    },
    withCredentials: true
  });

  return _client;
})();

export const handleError = (error: AxiosError, action?: Action<any> | Action<any>[]): Observable<Action<any> | null> => {
  // 例外アクション
  let exceptionAction;

  // 認証エラー発生時にログインページへ遷移
  if (error.response && isAuthenticationError(error)) {
    exceptionAction = of(sessionActions.logout.done({params: {}}));
  }

  const _action: Observable<Action<any>> | null = action ? (Array.isArray(action) ? from(action) : of(action)) : null;

  if (exceptionAction && _action) {
    return merge(exceptionAction, _action)
  } else if (exceptionAction) {
    return exceptionAction;
  } else if (_action) {
    return _action;
  }

  return empty();
}

// const request = (reqConfig: AxiosRequestConfig): Observable<T> => from(client.request({...reqConfig}));

export default function request<T = any>(reqConfig: AxiosRequestConfig): Observable<AxiosResponse<T>> {
  return from(client.request<T>({...reqConfig})).pipe(take(1));
};
