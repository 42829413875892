import React from 'react';
import { DirectMessage as IDirectMessage } from '../../../utils/interface/DirectMessage';
import { makeStyles } from '@material-ui/core';
import moment from 'moment';

const useStyles = makeStyles({
  root: {
    
  },
  timestamp: {
    fontSize: 14,
    marginBottom: 10,
    lineHeight: 1
  },
  body: {
    margin: 0,
    fontWeight: 600
  }
});

interface Props {
  message: IDirectMessage;
}

const DirectMessage: React.FC<Props> = (props) => {
  const classes = useStyles();

  return (
    <>
      <div className={ classes.root }>
        <div className={ classes.timestamp }>{ moment(props.message.sent_at).format('YYYY/MM/DD HH:mm') }</div>
        <p className={ classes.body }>{ props.message.body }</p>
      </div>
    </>
  );
}

export default DirectMessage;