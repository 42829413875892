import { Entity } from '../../redux/reducers/entities';
import { FetchStatus } from '../type/FetchStatus';

export function updateEntityState<T>(entityState: Entity<T>, newEntity: {[key: number]: T} | undefined): Entity<T> {
  if (newEntity) {
    const fetchStatus: {[key: number]: FetchStatus} = {};

    Object.keys(newEntity).forEach(key => {
      fetchStatus[Number(key)] = 'loaded';
    });

    return {
      ...entityState,
      entities: {
        ...entityState.entities,
        ...newEntity
      },
      fetchStatus: {
        ...entityState.fetchStatus,
        ...fetchStatus,
      }
    };
  }

  return entityState;
}
