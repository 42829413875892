import React from 'react';
import { makeStyles } from '@material-ui/core';
import { MachineMovieData } from '../../../utils/interface/MachineMovieData';

const useStyles = makeStyles({
  root: {
    width: '100%'
  },
  name: {
    fontWeight: 600,
    marginBottom: 10
  },
  video: {
    position: 'relative',
    width: '100%',
    paddingTop: '56.25%',
    zIndex: 0,
    backgroundColor: '#3c4245'
  },
  iframe: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%!important',
    height: '100%!important',
  }
});

interface Props {
  data: MachineMovieData;
}

const MachineMovie: React.FC<Props> = (props) => {
  const classes = useStyles();

  return (
    <>
      <div className={ classes.root }>
        <div className={ classes.name }>{ props.data.name }</div>
        <div className={ classes.video }>
          <iframe className={ classes.iframe } src={`https://www.youtube.com/embed/${ props.data.videoId }`} frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
        </div>
      </div>
    </>
  );
}

export default MachineMovie;