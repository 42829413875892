import { Epic, combineEpics } from 'redux-observable';
import { filter, switchMap, catchError, takeUntil, map } from 'rxjs/operators';
import * as usageHistoryActions from '../../actions/UsageHistoryAction';
import request, { handleError } from '../../../utils/function/request';
import { UsageHistoryData } from '../../../utils/interface/UsageHistoryData';

/**
 * 利用履歴を取得
 * 
 * @param action$ 
 */
const fetchUsageHistoryDataEpic: Epic = action$ => action$.pipe(
  filter(usageHistoryActions.fetchUsageHistoryData.started.match),
  switchMap(({payload}) => request<UsageHistoryData>({
    url: '/v1/usage_histories',
    params: {date: payload.format('YYYY-MM')}
  }).pipe(
    map(res => usageHistoryActions.fetchUsageHistoryData.done({params: payload, result: res.data})),
    catchError(error => handleError(error, usageHistoryActions.fetchUsageHistoryData.failed({
      params: payload,
      error
    }))),
    takeUntil(action$.pipe(
      filter(usageHistoryActions.cancelFetchingUsageHistoryData.match)
    ))
  ))
);

const usageHistoryEpic = combineEpics(
  fetchUsageHistoryDataEpic
);

export default usageHistoryEpic;