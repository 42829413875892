import { Entity } from '.';
import { reducerWithInitialState } from 'typescript-fsa-reducers';
import * as entityActions from '../../actions/EntityAction';
import { updateEntityState } from '../../../utils/function/updateEntityState';
import { UserEntity } from '../../../utils/interface/Entity/UserEntity';

const initialState: Entity<UserEntity> = {
  entities: [],
  fetchStatus: {},
  error: {}
};

const userEntitiesReducer = reducerWithInitialState(initialState)

// エンティティを更新
.case(entityActions.update, (state, payload) => updateEntityState(state, payload.users)
)

export default userEntitiesReducer;
