import actionCreatorFactory from 'typescript-fsa';
import { DirectMessageActionType as ActionType } from '../action-types/DirectMessageActionType';
import { PaginatePayload } from '../../utils/interface/PaginatePayload';
import { AxiosError } from 'axios';

const actionCreator = actionCreatorFactory('DirectMessage');

// 個別メッセージを取得
export const fetchDirectMessage = actionCreator.async<string, void, AxiosError>(ActionType.FETCH_DIRECT_MESSAGE);

// 個別メッセージの取得をキャンセル
export const cancelFetchingDirectMessage = actionCreator(ActionType.FETCH_DIRECT_MESSAGE_CANCELLED);

// 最新の個別メッセージを取得
export const fetchDirectMessagesLatest = actionCreator.async<{}, PaginatePayload, AxiosError>(ActionType.FETCH_DIRECT_MESSAGES_LATEST);

// 個別メッセージの更新を取得
export const fetchDirectMessagesUpdates = actionCreator.async<string, PaginatePayload, AxiosError>(ActionType.FETCH_DIRECT_MESSAGES_UPDATES);

// 個別メッセージをさらに取得
export const fetchDirectMessagesMore = actionCreator.async<string, PaginatePayload, AxiosError>(ActionType.FETCH_DIRECT_MESSAGES_MORE);

// 個別メッセージリストの取得をキャンセル
export const cancelFetchingDirectMessages = actionCreator(ActionType.FETCH_DIRECT_MESSAGES_CANCELLED);