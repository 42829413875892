import { Epic, combineEpics } from 'redux-observable';
import { filter, switchMap, map, catchError } from 'rxjs/operators';
import * as usageSituationActions from '../../actions/UsageSituationAction';
import request, { handleError } from '../../../utils/function/request';
import { CurrentUsageSituationRes } from '../../../utils/interface/CurrentUsageSituation';

const fetchCurrentUsageSituationEpic: Epic = action$ => action$.pipe(
  filter(usageSituationActions.fetchCurrentUsageSituation.started.match),
  switchMap(({payload}) => request<CurrentUsageSituationRes>({
    url: `/v1/gyms/${ payload }/usage_situation`
  }).pipe(
    map(res => usageSituationActions.fetchCurrentUsageSituation.done({
      params: payload,
      result: res.data
    })),
    catchError(error => handleError(error, usageSituationActions.fetchCurrentUsageSituation.failed({
      params: payload,
      error
    })))
  ))
);

const usageSituationEpic = combineEpics(
  fetchCurrentUsageSituationEpic
);

export default usageSituationEpic;