import React from 'react';
import { makeStyles, Typography, Button, CircularProgress } from '@material-ui/core';
import request from '../../../../utils/function/request';
import { finalize } from 'rxjs/operators';
import { AxiosError } from 'axios';
import { ReduxState } from '../../../../redux/reducers';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import queryString from 'query-string';
import { RouteComponentProps } from 'react-router';
import isValidationError from '../../../../utils/function/isValidationError';
import { AuthCredentials } from '../../../../utils/interface/AuthCredentials';
import * as toastActions from '../../../../redux/actions/ToastAction';
import * as sessionActions from '../../../../redux/actions/SessionAction';

interface FormValue {
  token: string;
  email: string;
  password: string;
  password_confirmation: string;
}

const initialFormValue: FormValue = {
  token: '',
  email: '',
  password: '',
  password_confirmation: ''
};

const useStyles = makeStyles({
  root: {
    display: 'flex',
    justifyContent: 'center',
  },
  inner: {
    width: '100%',
    maxWidth: 375,
    marginLeft: 'auto',
    marginRight: 'auto',
    padding: '70px 20px 40px',
  },
  logo: {
    textAlign: 'center',
    marginBottom: 10
  },
  title: {
    textAlign: 'center',
    marginBottom: 30
  },
  formInner: {
    borderRadius: 4,
    overflow: 'hidden',
    marginBottom: 20,
    border: '1px solid #bdbdbd'
  },
  formItem: {
    '& + &': {
      borderTop: '1px solid #bdbdbd'
    }
  },
  input: {
    '-webkit-appearance': 'none',
    height: 45,
    borderRadius: 0,
    border: 'none',
    outline: 'none',
    fontSize: 16,
    paddingLeft: 12,
    boxSizing: 'border-box',
    width: '100%',
    appearance: 'none',
    background: '#ffffff',
    '&::placeholder': {
      fontSize: 14
    }
  },
  button: {
    height: 40,
    boxShadow: 'none!important',
    fontWeight: 600,
    '&:disabled': {
      color: '#ffffff',
      backgroundColor: '#BDBDBD!important'
    }
  },
  reset: {
    textAlign: 'center',
    fontSize: 13,
    marginTop: 10
  },
  resetLink: {
    color: '#666666',
  }
});

interface Props extends RouteComponentProps {
  onSuccess: (credentials: AuthCredentials) => void;
  onFailed: (error: AxiosError) => void;
}

const ResetForm: React.FC<Props> = (props) => {
  const classes = useStyles();

  // クエリーパラメータを取得
  const queryParams = queryString.parse(props.location.search);

  const [formValue, setFormValue] = React.useState<FormValue>({
    ...initialFormValue,
    email: String(queryParams.email),
    token: String(queryParams.token),
  });
  const [isSending, setIsSending] = React.useState<boolean>(false);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormValue({
      ...formValue,
      [e.target.name]: e.target.value
    })
  }

  const handleSubmit= (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setIsSending(true);

    request<AuthCredentials>({
      method: 'post',
      url: '/v1/password/reset',
      data: formValue
    }).pipe(
      finalize(() => setIsSending(false))
    ).subscribe(
      res => props.onSuccess(res.data),
      error => props.onFailed(error)
    );
  }

  const isInvalid: boolean = (!formValue.password || !formValue.password_confirmation) ? true : false;

  return (
    <>
      <div className={ classes.root }>
        <div className={ classes.inner }>
          <Typography variant='h5' component='h1' className={ classes.title }>パスワード再設定</Typography>
          <p>新しいパスワードを入力してください</p>
          <form onSubmit={ handleSubmit }>
            <div className={ classes.formInner }>
              <div className={ classes.formItem }>
                <input type='password' name='password'
                  className={ classes.input }
                  placeholder='新しいパスワード'
                  value={ formValue.password }
                  onChange={ handleInputChange }
                  required
                />
              </div>
              <div className={ classes.formItem }>
                <input type='password' name='password_confirmation'
                  className={ classes.input }
                  placeholder='新しいパスワードを再入力してください'
                  value={ formValue.password_confirmation }
                  onChange={ handleInputChange }
                  required
                />
              </div>
            </div>
            <Button type='submit' fullWidth
              color='secondary'
              variant='contained' 
              className={ classes.button }
              disabled={ isSending || isInvalid }
            >
              {isSending ? (<CircularProgress size={ 16 } />) : '送信する'}
            </Button>
          </form>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state: ReduxState) => ({});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  onSuccess: (credentials: AuthCredentials) => dispatch(sessionActions.fetchAuthCredentialsSuccess(credentials)),
  onFailed: (error: AxiosError) => {
    if (error.response && isValidationError(error)) {
      for (const key in error.response.data.errors) {
        error.response.data.errors[key].forEach((message: string) => {
          dispatch(toastActions.show({message, type: 'error'}))
        });
      }
    } else {
      dispatch(toastActions.show({message: '問題が発生しました', type: 'error'}))
    }
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(ResetForm);