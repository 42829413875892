import actionCreatorFactory from 'typescript-fsa';
import { NewsActionType as ActionType } from '../action-types/NewsActionType';
import { PaginatePayload } from '../../utils/interface/PaginatePayload';
import { AxiosError } from 'axios';

const actionCreator = actionCreatorFactory('News');

// 最新のニュースを取得
export const fetchNewsListLatest = actionCreator.async<{}, PaginatePayload, AxiosError>(ActionType.FETCH_NEWS_LIST_LATEST);

// ニュースの更新を取得
export const fetchNewsListUpdates = actionCreator.async<string, PaginatePayload, AxiosError>(ActionType.FETCH_NEWS_LIST_UPDATES);

// ニュースをさらに取得
export const fetchNewsListMore = actionCreator.async<string, PaginatePayload, AxiosError>(ActionType.FETCH_NEWS_LIST_MORE);

// ニュースリストの取得をキャンセル
export const cancelFetchingNewsList = actionCreator(ActionType.FETCH_NEWS_LIST_CANCELLED);