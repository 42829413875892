import React from 'react';
import PageTitle from '../../../common/PageTitle/PageTitle';
import { AxiosError } from 'axios';
import { Dispatch } from 'redux';
import { ReduxState } from '../../../../redux/reducers';
import { connect } from 'react-redux';
import { makeStyles, Button, CircularProgress, InputBase } from '@material-ui/core';
import FormItem from '../../../common/FormItem/FormItem';
import request from '../../../../utils/function/request';
import { finalize } from 'rxjs/operators';
import { ValidationError } from '../../../../utils/interface/ValidationError';
import isValidationError from '../../../../utils/function/isValidationError';
import isAuthenticationError from '../../../../utils/function/isAuthenticationError';
import * as sessionActions from '../../../../redux/actions/SessionAction';
import * as toastActions from '../../../../redux/actions/ToastAction';
import { routerActions } from 'connected-react-router';

const useStyles = makeStyles({
  form: {
    padding: '30px 20px'
  },
  formItem: {
    '& + &': {
      marginTop: 25
    }
  },
  label: {
    display: 'inline-block',
    color: '#4d4d4d',
    fontSize: 13,
    marginBottom: 3
  },
  input: {
    height: 36,
    borderRadius: 3,
    border: '1px solid #bdbdbd',
    fontSize: 16,
    boxSizing: 'border-box',
    backgroundColor: '#ffffff',
    outline: 'none',

    '& input, & select': {
      paddingLeft: 8,
    }
  },
  buttonContainer: {
    marginTop: 50
  },
  button: {
    boxShadow: 'none!important',
    height: 40
  }
});

interface FormValue {
  password: string;
  new_password: string;
  new_password_confirmation: string;
}

const initialFormValue: FormValue = {
  password: '',
  new_password: '',
  new_password_confirmation: ''
};

interface Props {
  onSuccess: () => void;
  onFailed: (error: AxiosError) => void;
}

const PasswordSetting: React.FC<Props> = (props) => {
  const classes = useStyles();

  const [isSending, setIsSending] = React.useState<boolean>(false);
  const [formValue, setFormValue] = React.useState<FormValue>(initialFormValue);
  const [validationError, setValidationError] = React.useState<ValidationError>({});

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormValue({
      ...formValue,
      [e.target.name]: e.target.value
    });
  }

  const handleError = (error: AxiosError) => {
    if (error.response && isValidationError(error)) {
      setValidationError(error.response.data.errors);
    }
    props.onFailed(error);
  }

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsSending(true);
    setValidationError({});

    request({
      method: 'put',
      url: '/v1/setting/password',
      data: formValue,
    }).pipe(
      finalize(() => setIsSending(false))
    ).subscribe(
      res => props.onSuccess(),
      error => handleError(error)
    )
  }

  const isInvalid = (!formValue.password || !formValue.new_password || !formValue.new_password_confirmation)
      ? true : false;

  return (
    <>
      <PageTitle>パスワードの変更</PageTitle>

      <form className={ classes.form } onSubmit={ handleSubmit }>
        {/* 現在のパスワード */}
        <FormItem label='現在のパスワード' required
          error={ Boolean(validationError.password) }
          helperText={ validationError.password }
        >
          <InputBase fullWidth type='password' name='password'
            className={ classes.input }
            value={ formValue.password }
            onChange={ handleInputChange }
            disabled={ isSending }
            required
            error={ Boolean(validationError.password) }
          />
        </FormItem>

        {/* 新しいパスワード */}
        <FormItem label='新しいパスワード（半角英数字8文字以上）' required
          error={ Boolean(validationError.new_password) }
          helperText={ validationError.new_password }
        >
          <InputBase fullWidth type='password' name='new_password'
            className={ classes.input }
            value={ formValue.new_password }
            onChange={ handleInputChange }
            disabled={ isSending }
            required
            error={ Boolean(validationError.new_password) }
          />
        </FormItem>

        {/* 新しいパスワード（確認） */}
        <FormItem label='新しいパスワード（確認）' required
          error={ Boolean(validationError.new_password_confirmation) }
          helperText={ validationError.new_password_confirmation }
        >
          <InputBase fullWidth type='password' name='new_password_confirmation'
            className={ classes.input }
            value={ formValue.new_password_confirmation }
            onChange={ handleInputChange }
            disabled={ isSending }
            required
            error={ Boolean(validationError.new_password_confirmation) }
          />
        </FormItem>

        <div className={ classes.buttonContainer }>
          <Button type='submit' variant='contained' color='secondary'
            fullWidth
            disabled={ isSending || isInvalid }
            className={ classes.button }
          >
            {isSending ? (<CircularProgress size={ 16 } />) : '変更する'}
          </Button>
        </div>
      </form>
    </>
  );
}

const mapStateToProps = (state: ReduxState) => ({});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  onSuccess: () => {
    dispatch(routerActions.push('/settings'))
    dispatch(toastActions.show({message: 'パスワードを変更しました', type: 'success'}))
  },
  onFailed: (error: AxiosError) => {
    if (error.response && isAuthenticationError(error)) {
      dispatch(sessionActions.logout.done({params: {}}));
    } else {
      dispatch(toastActions.show({message: 'パスワードの変更に失敗しました', type: 'error'}));
    }
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(PasswordSetting);