export const NewsActionType = {
  // 最新のニュースを取得
  FETCH_NEWS_LIST_LATEST: 'FETCH_NEWS_LIST_LATEST',

  // ニュースの更新を取得
  FETCH_NEWS_LIST_UPDATES: 'FETCH_NEWS_LIST_UPDATES',

  // ニュースをさらに取得
  FETCH_NEWS_LIST_MORE: 'FETCH_NEWS_LIST_MORE',

  // ニュースリストの取得をキャンセル
  FETCH_NEWS_LIST_CANCELLED: 'FETCH_NEWS_LIST_CANCELLED',
};