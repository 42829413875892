import actionCreatorFactory from 'typescript-fsa';
import { ToastActionType as ActionType } from '../../redux/action-types/ToastActionType';
import { ToastType } from '../../utils/type/ToastType';

const actionCreator = actionCreatorFactory('Toast');

// トーストを表示
export const show = actionCreator<{message: string, type: ToastType}>(ActionType.TOAST_SHOWED);

// トーストを非表示
export const hide = actionCreator<number>(ActionType.TOAST_HIDDEN);
