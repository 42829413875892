import { createSelector } from 'reselect';
import { ReduxState } from '../../reducers';
import { EMPTY_STATE_CURSOR } from '../../reducers/entries';

const fetchStatusesSelector = (state: ReduxState, key: string) => state.entries[key]
  ? state.entries[key].fetchStatus
  : {};

const initialFetchStatusSelector = createSelector(
  [fetchStatusesSelector], 
  (fetchStatuses) => fetchStatuses[EMPTY_STATE_CURSOR] || null
);

export default initialFetchStatusSelector;