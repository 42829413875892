import { Toast } from '../../utils/interface/Toast';
import { reducerWithInitialState } from 'typescript-fsa-reducers';
import * as toastActions from '../actions/ToastAction';

export type ToastState = Toast[];

const initialState: ToastState = [];

const toastReducer = reducerWithInitialState(initialState)

// トーストを表示
.case(toastActions.show, (state, payload) => {
  const toasts = state.concat();

  toasts.unshift({
    id: toasts.length > 0 ? toasts[0].id + 1 : 1,
    ...payload
  });

  return toasts;
})

// トーストを非表示
.case(toastActions.hide, (state, payload) => {
  const toasts = state.concat();
  const index = toasts.findIndex(toast => toast.id === payload);

  if (index > -1) {
    toasts.splice(index, 1);
  }

  return toasts;
})

export default toastReducer;
