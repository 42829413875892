import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
  palette: {
    type: 'light',
    primary: {
      50: '#eaeaeb',
      100: '#cacbcc',
      200: '#a6a9ab',
      300: '#828689',
      400: '#686c6f',
      500: '#4d5256',
      600: '#464b4f',
      700: '#3d4145',
      800: '#34383c',
      900: '#25282b',
      A100: '#7ebcf9',
      A200: '#4ea2f7',
      A400: '#1389ff',
      A700: '#007cf8',
    },
    secondary: {
      main: '#E75297'
      // main: '#0068B6',
    },
  }
})

export default theme;
