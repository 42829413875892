import { createSelector } from 'reselect';
import { ReduxState } from '../../reducers';
import { UsageHistoryData } from '../../../utils/interface/UsageHistoryData';

const usageHistoryStateSelector = (state: ReduxState, key: string) => state.usageHistory[key] || null;

const usageHistoryDataSelector = createSelector(
  [usageHistoryStateSelector],
  (usageHistoryState): UsageHistoryData => {
    return usageHistoryState
      ? {
        total_minutes: usageHistoryState.total_minutes,
        histories: usageHistoryState.histories,
      }
      : {
        total_minutes: 0,
        histories: [],
      };
  }
);

export default usageHistoryDataSelector;