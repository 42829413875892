import React from 'react';
import { makeStyles } from '@material-ui/core';
import { Link } from 'react-router-dom';
import Logo from '../../common/Logo/Logo';
import MenuButton from '../MenuButton/MenuButton';
import Container from '../../common/Container/Container';
import NotificationButton from '../NotificationButton/NotificationButton';

const useStyles = makeStyles({
  root: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    zIndex: 300,
    backgroundColor: '#ffffff',
    boxShadow: '0 3px 6px rgba(0, 0, 0, 0.16)',
  },
  inner: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 16,
    paddingRight: 16,
    height: 60,
  },
  logo: {
    display: 'inline-block'
  },
  menuBtn: {
    marginRight: 5
  },
  message: {
    marginLeft: 'auto',
  }
});

interface Props {}

const Header: React.FC<Props> = (props) => {
  const classes = useStyles();

  return (
    <>
      <header className={ classes.root }>
        <Container className={ classes.inner }>
          <div className={ classes.menuBtn }>
            <MenuButton edge='start' />
          </div>
          <Link to='/' className={ classes.logo }>
            <Logo width='123px' height='20px' />
          </Link>
          <div className={ classes.message }>
            <NotificationButton />
          </div>
        </Container>
      </header>
    </>
  );
}

export default Header;