import React from 'react';
import { ReduxState } from '../../../../redux/reducers';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import * as usageSituationActions from '../../../../redux/actions/UsageSituationAction';
import { makeStyles, Typography, CircularProgress, IconButton } from '@material-ui/core';
import { FetchStatus } from '../../../../utils/type/FetchStatus';
import { CurrentUsageSituation as ICurrentUsageSituation } from '../../../../utils/interface/CurrentUsageSituation';
import moment, { Moment } from 'moment';
import { decimalPipe } from '../../../../utils/function/pipe/decimalPipe';
import RefreshIcon from '@material-ui/icons/Refresh'
import RunningIcon from '../../../icon/RunningIcon';
import BenchPressIcon from '../../../icon/BenchPressIcon';
import BoxingIcon from '../../../icon/BoxingIcon';

const useStyles = makeStyles({
  root: {
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundImage: "url('/img/top/usage_bg.jpg')",
    position: 'relative',
    zIndex: 0
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    minWidth: 0,
    padding: 16,
  },
  headerTitle: {
    color: '#ffffff',
    fontWeight: 600,
  },
  timestamp: {
    color: '#ffffff',
    fontSize: 12,
    marginLeft: 'auto',
  },
  reload: {
    color: '#ffffff',
  },
  reloadIcon: {
    fontSize: 16,
  },
  label: {
    color: '#313131',
    fontSize: 11,
    fontWeight: 600,
    backgroundColor: '#ffffff',
    textAlign: 'center',
    padding: '6px 3px',
    lineHeight: 1,
    borderRadius: '10px / 50%'
  },
  content: {
    display: 'flex',
    minWidth: 0,
  },
  contentLeft: {
    flexBasis: '64%',
    flexGrow: 1,
    boxSizing: 'border-box',
    padding: '0 16px'
  },
  contentRight: {
    flexBasis: '36%',
    flexGrow: 1,
    boxSizing: 'border-box',
    padding: '0 16px',
    borderLeft: '1px solid #ffffff'
  },
  contentItemWrapper: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: -8,
    marginRight: -8,
    '&:first-child': {
      marginBottom: 18
    },
    '& + &': {
      marginTop: 18
    }
  },
  contentItem: {
    flexBasis: '50%',
    boxSizing: 'border-box',
    paddingLeft: 8,
    paddingRight: 8
  },
  contentItemText: {
    color: '#ffffff',
    textAlign: 'center',
    fontSize: 18,
    fontWeight: 600,
  },
  numberOfPeople: {
    color: '#ffffff',
    textAlign: 'center',
    fontWeight: 600,
    marginTop: 10,
    fontSize: 40,
    lineHeight: 1,
    '&::after': {
      content: "'人'",
      fontSize: 16,
      marginLeft: '3px',
    }
  },
  machines: {
    display: 'flex',
    flexDirection: 'column'
  },
  machine: {
    flexBasis: 'auto',
    flexGrow: 1,
    display: 'flex',
    minWidth: 0,
    padding: '10px 0',
    '& + &': {
      borderTop: '1px solid #ffffff'
    },
  },
  machineIcon: {
    display: 'flex',
    alignItems: 'center',
    fontSize: 30,
    flexBasis: 40,
    flexGrow: 0,
    flexShrink: 0,
  },
  machineStatus: {
    flexBasis: 'auto',
    flexGrow: 1,
    flexShrink: 1,
    color: '#ffffff',
    textAlign: 'center',
    fontSize: 18,
    fontWeight: 600,
  },
  footer: {
    color: '#ffffff',
    fontSize: 10,
    padding: '16px 16px 10px'
  },
  loading: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(255, 255, 255, 0.4)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
});

interface Props {
  fetchStatus: FetchStatus;
  usageSituation: ICurrentUsageSituation | null;
  lastFetchTimestamp: Moment | null;
  loadUsageSituation: () => void;
  cancelLoading: () => void;
}

const CurrentUsageSituation: React.FC<Props> = (props) => {
  const classes = useStyles();

  const handleUpdate = () => {
    props.loadUsageSituation();
  }

  React.useEffect(() => {
    if (props.fetchStatus !== 'loaded') {
      props.loadUsageSituation();
    }

    return () => {
      props.cancelLoading();
    }
  }, []);

  return (
    <>
      <div className={ classes.root }>
        <div className={ classes.header }>
          <Typography className={ classes.headerTitle }>施設利用状況</Typography>
          <Typography className={ classes.timestamp }>
            {props.lastFetchTimestamp
              ? `${moment(props.lastFetchTimestamp).format('MM月DD日HH時mm分')}の状況`
              : '--月--日--時--分の状況'
            }
          </Typography>
          <IconButton className={ classes.reload } size='small' onClick={ handleUpdate }>
            <RefreshIcon className={ classes.reloadIcon } />
          </IconButton>
        </div>
        <div className={ classes.content }>
          <div className={ classes.contentLeft }>
            <div className={ classes.contentItemWrapper }>
              <div className={ classes.contentItem }>
                <div className={ classes.label }>男性利用者</div>
                <div className={ classes.numberOfPeople }>
                  {props.usageSituation
                    ? decimalPipe(props.usageSituation.number_of_people.male)
                    : '-'
                  }
                </div>
              </div>
              <div className={ classes.contentItem }>
                <div className={ classes.label }>女性利用者</div>
                <div className={ classes.numberOfPeople }>
                  {props.usageSituation
                    ? decimalPipe(props.usageSituation.number_of_people.female)
                    : '-'
                  }
                </div>
              </div>
            </div>
            <div className={ classes.contentItemWrapper }>
              <div className={ classes.contentItem }>
                <div className={ classes.label }>混雑具合</div>
              </div>
              <div className={ classes.contentItem }>
                <div className={ classes.contentItemText }>-</div>
              </div>
            </div>
            <div className={ classes.contentItemWrapper }>
              <div className={ classes.contentItem }>
                <div className={ classes.label }>駐車場予測</div>
              </div>
              <div className={ classes.contentItem }>
                <div className={ classes.contentItemText }>-</div>
              </div>
            </div>
          </div>
          <div className={ classes.contentRight }>
            <div className={ classes.label }>マシン利用予測</div>
            <div className={ classes.machines }>
              <div className={ classes.machine }>
                <div className={ classes.machineIcon }>
                  <RunningIcon />
                </div>
                <div className={ classes.machineStatus }>-</div>
              </div>
              <div className={ classes.machine }>
                <div className={ classes.machineIcon }>
                  <BenchPressIcon />
                </div>
                <div className={ classes.machineStatus }>-</div>
              </div>
              <div className={ classes.machine }>
                <div className={ classes.machineIcon }>
                  <BoxingIcon />
                </div>
                <div className={ classes.machineStatus }>-</div>
              </div>
            </div>
          </div>
        </div>
        <div className={ classes.footer }>
          ※実際の利用状況とは異なる場合がありますので、ご了承ください。
        </div>
        {props.fetchStatus === 'loading' && (
          <div className={ classes.loading }>
            <CircularProgress />
          </div>
        )}
      </div>
    </>
  );
}

const mapStateToProps = (state: ReduxState) => ({
  usageSituation: state.currentUsageSituation.usageSituation,
  fetchStatus: state.currentUsageSituation.fetchStatus,
  lastFetchTimestamp: state.currentUsageSituation.lastFetchTimestamp,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  loadUsageSituation: () => dispatch(usageSituationActions.fetchCurrentUsageSituation.started(1)),
  cancelLoading: () => {}
});

export default connect(mapStateToProps, mapDispatchToProps)(CurrentUsageSituation);