import React from 'react';
import PageTitle from '../../../common/PageTitle/PageTitle';
import { ReduxState } from '../../../../redux/reducers';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { makeStyles, List, ListItem } from '@material-ui/core';
import { FetchStatus } from '../../../../utils/type/FetchStatus';
import { DirectMessage as IDirectMessage } from '../../../../utils/interface/DirectMessage';
import { directMessageStateKey } from '../../../../redux/reducers/entries/directMessage';
import initialFetchStatusSelector from '../../../../redux/selectors/entry/initialFetchStatusSelector';
import topFetchStatusSelector from '../../../../redux/selectors/entry/topFetchStatusSelector';
import bottomFetchStatusSelector from '../../../../redux/selectors/entry/bottomFetchStatusSelector';
import topCursorSelector from '../../../../redux/selectors/entry/topCursorSelector';
import bottomCursorSelector from '../../../../redux/selectors/entry/bottomCursorSelector';
import directMessagesSelector from '../../../../redux/selectors/directMessage/directMessagesSelector';
import * as directMessageActions from '../../../../redux/actions/DirectMessageAction';
import DirectMessage from '../../../common/DirectMessage/DirectMessage';
import InfiniteScrollWrapper from '../../../common/InfiniteScrollWrapper/InfiniteScrollWrapper';
import Progress from '../../../common/Progress/Progress';
import EmptyMessage from '../../../common/EmptyMessage/EmptyMessage';
import WarningMessage from '../../../common/WarningMessage/WarningMessage';
import PullToRefreshWrapper from '../../../common/PullToRefreshWrapper/PullToRefreshWrapper';

const useStyles = makeStyles({
  list: {
    backgroundColor: '#ffffff'
  },
  listItem: {
    padding: 15
  }
});

interface Props {
  initialFetchStatus: FetchStatus;
  topFetchStatus: FetchStatus;
  bottomFetchStatus: FetchStatus;
  topCursor: string | null;
  bottomCursor: string | null;
  messages: IDirectMessage[];
  loadLatest: () => void;
  loadUpdates: (cursor: string) => void;
  loadMore: (cursor: string) => void;
  cancelLoading: () => void;
}

const MessageList: React.FC<Props> = (props) => {
  const classes = useStyles();

  const handleRefresh = () => {
    if (props.topCursor) {
      props.loadUpdates(props.topCursor);
    } else {
      props.loadLatest();
    }
  }

  const handleLoadMore = () => {
    if (props.bottomCursor) {
      props.loadMore(props.bottomCursor);
    }
  }

  const handleReload = () => {
    props.loadLatest();
  }

  React.useEffect(() => {
    if (props.initialFetchStatus !== 'loaded') {
      props.loadLatest();
    }

    return () => {
      props.cancelLoading();
    }
  }, []);

  return (
    <>
      <PageTitle>個別メッセージ</PageTitle>

      {props.initialFetchStatus === 'loading' && (<Progress />)}

      {props.initialFetchStatus === 'loaded' && (
        <PullToRefreshWrapper
          fetchStatus={ props.topFetchStatus }
          onRefresh={ handleRefresh }
        >
          {props.messages.length > 0
            ? (
              <InfiniteScrollWrapper
                hasMore={ Boolean(props.bottomCursor) }
                loadMore={ handleLoadMore }
              >
                <List className={ classes.list } disablePadding>
                  {props.messages.map(message => (
                    <ListItem divider className={ classes.listItem }  key={ message.id }>
                      <DirectMessage message={ message } />
                    </ListItem>
                  ))}
                </List>
              </InfiniteScrollWrapper>
            )
            : (
              <div style={{ padding: '0 16px' }}>
                <EmptyMessage>メッセージはありません</EmptyMessage>
              </div>
            )
          }
        </PullToRefreshWrapper>
      )}
      
      {props.initialFetchStatus === 'failed' && (
        <div style={{padding: '30px 16px'}}>
          <WarningMessage withButton onClick={ handleReload }/>
        </div>
      )}
    </>
  );
}

const mapStateToProps = (state: ReduxState) => ({
  initialFetchStatus: initialFetchStatusSelector(state, directMessageStateKey),
  topFetchStatus: topFetchStatusSelector(state, directMessageStateKey),
  bottomFetchStatus: bottomFetchStatusSelector(state, directMessageStateKey),
  topCursor: topCursorSelector(state, directMessageStateKey),
  bottomCursor: bottomCursorSelector(state, directMessageStateKey),
  messages: directMessagesSelector(state, directMessageStateKey)
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  loadLatest: () => dispatch(directMessageActions.fetchDirectMessagesLatest.started({})),
  loadUpdates: (cursor: string) => dispatch(directMessageActions.fetchDirectMessagesUpdates.started(cursor)),
  loadMore: (cursor: string) => dispatch(directMessageActions.fetchDirectMessagesMore.started(cursor)),
  cancelLoading: () => dispatch(directMessageActions.cancelFetchingDirectMessages())
});

export default connect(mapStateToProps, mapDispatchToProps)(MessageList);