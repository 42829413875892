import { History } from 'history';
import { combineReducers } from 'redux';
import * as sessionActions from '../actions/SessionAction';
import { RouterState, connectRouter } from 'connected-react-router';
import layoutReducer, { LayoutState } from './layoutReducer';
import sessionReducer, { SessionState } from './sessionReducer';
import toastReducer, { ToastState } from './toastReducer';
import entitiesReducer, { EntitiesState } from './entities';
import { reducerWithoutInitialState } from 'typescript-fsa-reducers';
import currentUsageSituationReducer, { CurrentUsageSituationState } from './currentUsageSituationReducer';
import entriesReducer, { EntryReducerState } from './entries';
import usageHistoryReducer, { UsageHistoryReducerState } from './usageHistory';

export interface ReduxState {
  session: SessionState;
  layout: LayoutState;
  toast: ToastState;
  entities: EntitiesState;
  entries: EntryReducerState
  currentUsageSituation: CurrentUsageSituationState;
  usageHistory: UsageHistoryReducerState;
  router: RouterState;
}

const reducers = (history: History) => {
  const appReducer = combineReducers<ReduxState, any>({
    session: sessionReducer,
    layout: layoutReducer,
    toast: toastReducer,
    entities: entitiesReducer,
    entries: entriesReducer,
    currentUsageSituation: currentUsageSituationReducer,
    usageHistory: usageHistoryReducer,
    router: connectRouter(history)
  });

  return reducerWithoutInitialState<ReduxState>()

  // ログアウト - 成功
  .caseWithAction(sessionActions.logout.done, (state, action) => appReducer(undefined, action))

  .default((state, action) => appReducer(state, action));
}

export default reducers;