export const SessionActionType = {
  // 認証資格の取得成功
  FETCH_AUTH_CREDENTIALS_SUCCESSFULL: 'FETCH_AUTH_CREDENTIALS_SUCCESSFULL',

  // 認証資格の取得失敗
  FETCH_AUTH_CREDENTIALS_FAILED: 'FETCH_AUTH_CREDENTIALS_FAILED',

  // 認証ユーザーIDを保存
  AUTH_USER_ID_STORED: 'AUTH_USER_ID_STORED',

  // 未読件数を取得
  FETCH_UNREAD_DIRECT_MESSAGE_COUNT: 'FETCH_UNREAD_DIRECT_MESSAGE_COUNT',

  // 未読件数の取得をキャンセル
  FETCH_UNREAD_DIRECT_MESSAGE_COUNT_CANCELLED: 'FETCH_UNREAD_DIRECT_MESSAGE_COUNT_CANCELLED',

  // ログアウト
  LOGOUT: 'LOGOUT'
};
