import { createSelector } from 'reselect';
import { ReduxState } from '../../reducers';
import { PaymentHistory } from '../../../utils/interface/PaymentHistory';

const paymentHistoriesEntitiesSelector = (state: ReduxState) => state.entities.paymentHistories.entities;
const entriesSelector = (state: ReduxState, key: string) => state.entries[key]
  ? state.entries[key].entries
  : [];

const paymentHistoriesSelector = createSelector(
  [paymentHistoriesEntitiesSelector, entriesSelector],
  (paymentHistoriesEntities, entries) => {
    const histories: PaymentHistory[] = [];

    entries.forEach(historyId => {
      const paymentHistoryEntity = paymentHistoriesEntities[historyId] || null; 

      if (paymentHistoryEntity) {
        histories.push({
          ...paymentHistoryEntity,
        });
      }
    });

    return histories;
  }
);

export default paymentHistoriesSelector;