import { Epic, combineEpics } from 'redux-observable';
import * as entityActions from '../../../redux/actions/EntityAction';
import * as sessionActions from '../../../redux/actions/SessionAction';
import { filter, switchMap, map, catchError, mergeMap, takeUntil } from 'rxjs/operators';
import { normalize } from 'normalizr';
import schemas from '../../schemas';
import request, { handleError } from '../../../utils/function/request';
import { of } from 'rxjs';
import { UnreadCount } from '../../../utils/interface/UnreadCount';

/**
 * 認証資格情報の取得に成功
 * 
 * @param action$ 
 */
const fetchAuthCredentialsSuccessEpic: Epic = action$ => action$.pipe(
  filter(sessionActions.fetchAuthCredentialsSuccess.match),
  mergeMap(({payload}) => {
    const normalizedData = normalize(payload.user, schemas.user);

    return of(
      entityActions.update(normalizedData.entities),
      sessionActions.storeAuthUserId(normalizedData.result),
      sessionActions.fetchUnreadCount.done({params: {}, result: payload.unread_direct_messages_count})
    );
  })
);

/**
 * 未読件数を取得
 * 
 * @param action$ 
 */
const fetchUreadCountEpic: Epic = action$ => action$.pipe(
  filter(sessionActions.fetchUnreadCount.started.match),
  switchMap(({payload}) => request<UnreadCount>({
    url: '/v1/direct_messages/unread_count'
  }).pipe(
    map(res => sessionActions.fetchUnreadCount.done({
      params: payload,
      result: res.data.unread_direct_messages_count
    })),
    catchError(error => handleError(error, sessionActions.fetchUnreadCount.failed({
      params: payload,
      error
    }))),
    takeUntil(action$.pipe(
      filter(sessionActions.cancelFetchingUnreadCount.match)
    ))
  ))
);

/**
 * ログアウト
 * 
 * @param action$ 
 */
const logoutEpic: Epic = action$ => action$.pipe(
  filter(sessionActions.logout.started.match),
  switchMap(({payload}) => request({
    method: 'post',
    url: '/v1/logout',
  }).pipe(
    map(res => sessionActions.logout.done({params: payload, result: res.data})),
    catchError(error => handleError(error, sessionActions.logout.failed({
      params: payload,
      error
    })))
  ))
);

const sessionEpic = combineEpics(
  fetchAuthCredentialsSuccessEpic,
  fetchUreadCountEpic,
  logoutEpic
);

export default sessionEpic;