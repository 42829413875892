import React from 'react';
import styles from './PullToRefreshWrapper.module.scss';
import { PullToRefresh } from 'react-js-pull-to-refresh';
import Progress from '../Progress/Progress';
import { makeStyles } from '@material-ui/core';
import { BehaviorSubject } from 'rxjs';
import { FetchStatus } from '../../../utils/type/FetchStatus';
import { filter } from 'rxjs/operators';
import ArrowDownIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpIcon from '@material-ui/icons/ArrowUpward';

const useStyles = makeStyles({
  content: {
    color: '#bdbdbd',
    fontSize: 20,
    textAlign: 'center',
    height: 120,
    paddingTop: 10,
    boxSizing: 'border-box',
  },
  refreshContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 70,
  }
})

interface PullToRefreshWrapperProps {
  fetchStatus: FetchStatus
  onRefresh: () => void;
}

const PullToRefreshWrapper: React.FC<PullToRefreshWrapperProps> = (props) => {

  const classes = useStyles();

  const isTouchDevice = ('ontouchstart' in window || navigator.msPointerEnabled) ? true : false;

  const [fetchStatusSubject, setFetchStatusSubject] = React.useState<BehaviorSubject<FetchStatus>>(
    new BehaviorSubject<FetchStatus>(null)
  );

  const handleRefresh = (): Promise<void> => {
    props.onRefresh();

    // Streamの値を初期化
    fetchStatusSubject.next(null);

    return new Promise((resolve, reject) => {
      fetchStatusSubject.asObservable().pipe(
        filter(status => status === 'loaded' || status === 'failed'),
      ).subscribe(
        status => status === 'loaded' ? resolve() : reject()
      );
    })
  }

  React.useEffect(() => {
    fetchStatusSubject.next(props.fetchStatus);
  }, [props.fetchStatus]);

  return (
    <>
      <div className={ styles.root }>
        {isTouchDevice
          ? (
            <PullToRefresh
              pullDownContent={
                <div className={ classes.content }>
                  <ArrowDownIcon />
                </div>
              }
              releaseContent={
                <div className={ classes.content }>
                  <ArrowUpIcon />
                </div>
              }
              refreshContent={ 
                <div className={ classes.refreshContent }>
                  <Progress />
                </div>
              }
              pullDownThreshold={ 80 }
              onRefresh={ handleRefresh }
              triggerHeight={ 'auto' }
              startInvisible={ true }
            >
              { props.children }
            </PullToRefresh>
          )
          : props.children
        }
      </div>
    </>
  );
}

export default PullToRefreshWrapper;