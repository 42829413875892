import { reducerWithoutInitialState } from 'typescript-fsa-reducers';
import { EntryState, initialEntryState, EMPTY_STATE_CURSOR, EntryReducerState } from '..';
import * as directMessageActions from '../../../actions/DirectMessageAction';
import { mergeEntries } from '../../../../utils/function/mergeEntries';
import moment from 'moment';

export const directMessageStateKey = 'directMessages';

const reducer = reducerWithoutInitialState<EntryState>()

// 最新の個別メッセージを取得 - 開始
.case(directMessageActions.fetchDirectMessagesLatest.started, (state, payload) => ({
  ...initialEntryState
}))

// 最新の個別メッセージを取得 - 成功
.case(directMessageActions.fetchDirectMessagesLatest.done, (state, {params, result}) => ({
  ...state,
  entries: mergeEntries(state.entries, result.entries),
  cursor: {
    ...state.cursor,
    top: result.top_cursor,
    bottom: result.bottom_cursor
  },
  lastFetchTimestamp: moment(),
  fetchStatus: {
    ...state.fetchStatus,
    [EMPTY_STATE_CURSOR]: 'loaded'
  }
}))

/**
 * 個別メッセージの更新を取得 - 開始
 * 個別メッセージをさらに取得 - 開始
 */
.cases([
  directMessageActions.fetchDirectMessagesUpdates.started, 
  directMessageActions.fetchDirectMessagesMore.started
], (state, payload) => ({
  ...state,
  fetchStatus: {
    ...state.fetchStatus,
    [payload]: 'loading'
  }
}))

// 個別メッセージの更新を取得 - 成功
.case(directMessageActions.fetchDirectMessagesUpdates.done, (state, {params, result}) => ({
  ...state,
  entries: mergeEntries(state.entries, result.entries),
  cursor: {
    ...state.cursor,
    top: result.top_cursor
  },
  lastFetchTimestamp: moment(),
  fetchStatus: {
    ...state.fetchStatus,
    [params]: 'loaded'
  }
}))

// 個別メッセージをさらに取得 - 成功
.case(directMessageActions.fetchDirectMessagesMore.done, (state, {params, result}) => ({
  ...state,
  entries: mergeEntries(state.entries, result.entries, true),
  cursor: {
    ...state.cursor,
    bottom: result.bottom_cursor
  },
  lastFetchTimestamp: moment(),
  fetchStatus: {
    ...state.fetchStatus,
    [params]: 'loaded'
  }
}))

// 個別メッセージの取得に失敗
.case(directMessageActions.fetchDirectMessagesLatest.failed, (state, payload) => ({
  ...state,
  lastFetchTimestamp: moment(),
  fetchStatus: {
    ...state.fetchStatus,
    [EMPTY_STATE_CURSOR]: 'failed'
  }
}))

// 個別メッセージの取得に失敗
.cases([
  directMessageActions.fetchDirectMessagesUpdates.failed, 
  directMessageActions.fetchDirectMessagesMore.failed
], (state, payload) => ({
  ...state,
  lastFetchTimestamp: moment(),
  fetchStatus: {
    ...state.fetchStatus,
    [payload.params]: 'failed'
  }
}))

const directMessageReducer = reducerWithoutInitialState<EntryReducerState>()

.default((state, action) => ({
  ...state,
  [directMessageStateKey]: reducer(state[directMessageStateKey], action),
}));

export default directMessageReducer;