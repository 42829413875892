import React from 'react';
import { AxiosError } from 'axios';
import AppShell from './components/layout/AppShell/AppShell';
import { CssBaseline } from '@material-ui/core';
import SplashScreen from './components/layout/SplashScreen/SplashScreen';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import request from './utils/function/request';
import { AuthCredentials } from './utils/interface/AuthCredentials';
import { finalize } from 'rxjs/operators';
import * as sessionActions from './redux/actions/SessionAction';
import { Switch } from 'react-router';
import routes from './routes';
import Toasts from './components/layout/Toasts/Toasts';

interface Props {
  onSuccess: (credentials: AuthCredentials) => void;
  onFailed: (error: AxiosError) => void;
}

const App: React.FC<Props> = (props) => {

  const [isLoading, setIsLoading] = React.useState<boolean | null>(null);

  React.useEffect(() => {
    setIsLoading(true);

    request<AuthCredentials>({url: '/v1/account'}).pipe(
      finalize(() => setIsLoading(false))
    ).subscribe(
      res => props.onSuccess(res.data),
      error => props.onFailed(error)
    );
  }, []);

  return (
    <>
      <CssBaseline />
      <Toasts />
      {isLoading !== null && (
        isLoading
          ? (<SplashScreen />)
          : (
            <AppShell>
              <Switch>{ routes }</Switch>
            </AppShell>
          )
      )}
    </>
  );
}

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  onSuccess: (credentials: AuthCredentials) => dispatch(sessionActions.fetchAuthCredentialsSuccess(credentials)),
  onFailed: (error: AxiosError) => dispatch(sessionActions.fetchAuthCredentialsFailed(error)),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
