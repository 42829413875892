import React from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import Progress from '../Progress/Progress';

export interface InfiniteScrollWrapperProps {
  pageStart?: number;
  loadMore: (page: number) => void;
  hasMore: boolean;
  initialLoad?: boolean;
  loader?: JSX.Element;
}

const InfiniteScrollWrapper: React.FC<InfiniteScrollWrapperProps> = props => {
  return (
    <InfiniteScroll
      style={{
        display: 'flex',
        flexDirection: 'column',
        flexBasis: 'auto',
        flexGrow: 1
      }}
      pageStart={ props.pageStart }
      loadMore={ props.loadMore }
      hasMore={ props.hasMore }
      initialLoad={ props.initialLoad }
      loader={ props.loader }
    >
      {props.children}
    </InfiniteScroll>
  );
}

InfiniteScrollWrapper.defaultProps = {
  pageStart: 1,
  initialLoad: false,
  loader: (
    <Progress key="progress" />
  )
};

export default InfiniteScrollWrapper;