import { reducerWithoutInitialState } from 'typescript-fsa-reducers';
import { EntryState, initialEntryState, EMPTY_STATE_CURSOR } from '..';
import * as newsActions from '../../../actions/NewsAction';
import { mergeEntries } from '../../../../utils/function/mergeEntries';
import moment from 'moment';

export const newsStateKey = 'news';

const newsReducer = reducerWithoutInitialState<EntryState>()

// ユーザーの最新の投稿を取得 - 開始
.case(newsActions.fetchNewsListLatest.started, (state, payload) => ({
  ...initialEntryState
}))

// ユーザーの最新の投稿を取得 - 成功
.case(newsActions.fetchNewsListLatest.done, (state, {params, result}) => ({
  ...state,
  entries: mergeEntries(state.entries, result.entries),
  cursor: {
    ...state.cursor,
    top: result.top_cursor,
    bottom: result.bottom_cursor
  },
  lastFetchTimestamp: moment(),
  fetchStatus: {
    ...state.fetchStatus,
    [EMPTY_STATE_CURSOR]: 'loaded'
  }
}))

/**
 * ユーザーの投稿の更新を取得 - 開始
 * ユーザーの投稿をさらに取得 - 開始
 */
.cases([
  newsActions.fetchNewsListUpdates.started, 
  newsActions.fetchNewsListMore.started
], (state, payload) => ({
  ...state,
  fetchStatus: {
    ...state.fetchStatus,
    [payload]: 'loading'
  }
}))

// ユーザーの投稿の更新を取得 - 成功
.case(newsActions.fetchNewsListUpdates.done, (state, {params, result}) => ({
  ...state,
  entries: mergeEntries(state.entries, result.entries),
  cursor: {
    ...state.cursor,
    top: result.top_cursor
  },
  lastFetchTimestamp: moment(),
  fetchStatus: {
    ...state.fetchStatus,
    [params]: 'loaded'
  }
}))

// ユーザーの投稿をさらに取得 - 成功
.case(newsActions.fetchNewsListMore.done, (state, {params, result}) => ({
  ...state,
  entries: mergeEntries(state.entries, result.entries, true),
  cursor: {
    ...state.cursor,
    bottom: result.bottom_cursor
  },
  lastFetchTimestamp: moment(),
  fetchStatus: {
    ...state.fetchStatus,
    [params]: 'loaded'
  }
}))

export default newsReducer;
