import React from 'react';
import { makeStyles, CircularProgress } from '@material-ui/core';
import Logo from '../../common/Logo/Logo';

const useStyles = makeStyles({
  root: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    backgroundColor: '#ffffff'
  },
  progress: {
    marginTop: 30
  }
});

interface Props {}

const SplashScreen: React.FC<Props> = (props) => {
  const classes = useStyles();

  return (
    <>
      <div className={ classes.root }>
        <Logo width='221.14px' height='36px' />
        <CircularProgress size={ 36 } color='secondary' className={ classes.progress } />
      </div>
    </>
  );
}

export default SplashScreen;