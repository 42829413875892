import actionCreatorFactory from 'typescript-fsa';
import { UsageHistoryActionType as ActionType } from '../../redux/action-types/UsageHistoryActionType';
import { AxiosError } from 'axios';
import { Moment } from 'moment';
import { UsageHistoryData } from '../../utils/interface/UsageHistoryData';

const actionCreator = actionCreatorFactory('UsageHistory');

// 利用履歴を取得
export const fetchUsageHistoryData = actionCreator.async<Moment, UsageHistoryData, AxiosError>(ActionType.FETCH_USAGE_HISTORY_DATA);

// 利用履歴の取得をキャンセル
export const cancelFetchingUsageHistoryData = actionCreator(ActionType.FETCH_USAGE_HISTORY_DATA_CANCELLED);