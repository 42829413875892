import { reducerWithInitialState } from 'typescript-fsa-reducers';
import * as layoutActions from '../actions/LayoutAction';

export interface LayoutState {
  menuIsActive: boolean;
}

const initialState: LayoutState = {
  menuIsActive: false,
};

const layoutReducer = reducerWithInitialState(initialState)

// メニューを開く
.case(layoutActions.openMenu, state => ({
  ...state,
  menuIsActive: true,
}))

// メニューを閉じる
.case(layoutActions.closeMenu, state => ({
  ...state,
  menuIsActive: false,
}))

export default layoutReducer;