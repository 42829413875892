import { FetchStatus } from '../../utils/type/FetchStatus';
import { CurrentUsageSituation } from '../../utils/interface/CurrentUsageSituation';
import moment, { Moment } from 'moment';
import { reducerWithInitialState } from 'typescript-fsa-reducers';
import * as usageSituationActions from '../actions/UsageSituationAction';

export interface CurrentUsageSituationState {
  fetchStatus: FetchStatus;
  usageSituation: CurrentUsageSituation | null;
  lastFetchTimestamp: Moment | null;
}

const initialState: CurrentUsageSituationState = {
  fetchStatus: null,
  usageSituation: null,
  lastFetchTimestamp: null
};

const currentUsageSituationReducer = reducerWithInitialState(initialState)

// 現在の利用状況を取得 - 開始
.case(usageSituationActions.fetchCurrentUsageSituation.started, (state, payload) => ({
  ...state,
  fetchStatus: 'loading',
}))

// 現在の利用状況を取得 - 成功
.case(usageSituationActions.fetchCurrentUsageSituation.done, (state, payload) => ({
  ...state,
  fetchStatus: 'loaded',
  usageSituation: payload.result.usage_situation,
  lastFetchTimestamp: moment(payload.result.timestamp)
}))

// 現在の利用状況を取得 - 失敗
.case(usageSituationActions.fetchCurrentUsageSituation.failed, (state, payload) => ({
  ...state,
  fetchStatus: 'failed'
}))

export default currentUsageSituationReducer;