import React from 'react';
import { ReduxState } from '../../../../redux/reducers';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { News as INews } from '../../../../utils/interface/News';
import { List, ListItem } from '@material-ui/core';
import News from '../../../common/News/News';
import { FetchStatus } from '../../../../utils/type/FetchStatus';
import * as newsActions from '../../../../redux/actions/NewsAction';
import initialFetchStatusSelector from '../../../../redux/selectors/entry/initialFetchStatusSelector';
import { newsStateKey } from '../../../../redux/reducers/entries/news/newsReducer';
import topFetchStatusSelector from '../../../../redux/selectors/entry/topFetchStatusSelector';
import topCursorSelector from '../../../../redux/selectors/entry/topCursorSelector';
import bottomFetchStatusSelector from '../../../../redux/selectors/entry/bottomFetchStatusSelector';
import bottomCursorSelector from '../../../../redux/selectors/entry/bottomCursorSelector';
import newsListSelector from '../../../../redux/selectors/news/newsListSelector';
import EmptyMessage from '../../../common/EmptyMessage/EmptyMessage';

interface Props {
  initialFetchStatus: FetchStatus;
  topFetchStatus: FetchStatus;
  bottomFetchStatus: FetchStatus;
  topCursor: string | null;
  bottomCursor: string | null;
  newsList: INews[];
  loadLatest: () => void;
  loadUpdates: (cursor: string) => void;
  loadMore: (cursor: string) => void;
  cancelLoading: () => void;
}

const NewsList: React.FC<Props> = (props) => {
  const handleLoadMore = () => {
    if (props.bottomCursor) {
      props.loadMore(props.bottomCursor);
    }
  }

  React.useEffect(() => {
    if (props.initialFetchStatus !== 'loaded') {
      props.loadLatest();
    }

    return () => {
      props.cancelLoading();
    }
  }, []);

  return (
    <>
      {props.newsList.length > 0
        ? (
          <List>
            {props.newsList.map(news => (
              <ListItem key={ news.id }>
                <News news={ news } />
              </ListItem>
            ))}
          </List>
        )
        : (<EmptyMessage>ご連絡はありません</EmptyMessage>)
      }
      
    </>
  );
}

const mapStateToProps = (state: ReduxState) => ({
  initialFetchStatus: initialFetchStatusSelector(state, newsStateKey),
  topFetchStatus: topFetchStatusSelector(state, newsStateKey),
  bottomFetchStatus: bottomFetchStatusSelector(state, newsStateKey),
  topCursor: topCursorSelector(state, newsStateKey),
  bottomCursor: bottomCursorSelector(state, newsStateKey),
  newsList: newsListSelector(state, newsStateKey)
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  loadLatest: () => dispatch(newsActions.fetchNewsListLatest.started({})),
  loadUpdates: (cursor: string) => dispatch(newsActions.fetchNewsListUpdates.started(cursor)),
  loadMore: (cursor: string) => dispatch(newsActions.fetchNewsListMore.started(cursor)),
  cancelLoading: () => dispatch(newsActions.cancelFetchingNewsList())
});

export default connect(mapStateToProps, mapDispatchToProps)(NewsList);