import React from 'react';
import ToastMessage from '../ToastMessage/ToastMessage';
import { makeStyles } from '@material-ui/styles';
import theme from '../../../configs/material-ui.config';
import { Toast as IToast } from '../../../utils/interface/Toast';
import { ReduxState } from '../../../redux/reducers';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { Fade } from '@material-ui/core';
import { TransitionGroup } from 'react-transition-group';

const useStyles = makeStyles({
  root: {
    position: 'fixed',
    maxWidth: 500,
    top: theme.spacing(2),
    left: theme.spacing(2),
    right: theme.spacing(2),
    padding: 0,
    margin: 0,
    marginLeft: 'auto',
    zIndex: 1700
  },
  item: {
    listStyle: 'none',
    '& + &': {
      marginTop: 10,
    }
  }
});

interface ToastsProps {
  toasts: IToast[];
}

const Toasts: React.FC<ToastsProps> = (props) => {

  const classes = useStyles();

  return (
    <>
      <TransitionGroup component='ul' className={ classes.root }>
        {props.toasts.map(toast => (
          <Fade key={ `toast_${ toast.id }` }>
            <li className={ classes.item }>
              <ToastMessage toast={ toast } />
            </li>
          </Fade>
        ))}
      </TransitionGroup>
    </>
  );
}

const mapStateToProps = (state: ReduxState) => ({
  toasts: state.toast
});

const mapDispatchToProps = (dispatch: Dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Toasts);
