import { ReduxState } from '../../reducers';
import { createSelector } from 'reselect';

const fetchStatusesSelector = (state: ReduxState, key: string) => state.entries[key]
  ? state.entries[key].fetchStatus
  : {};
const bottomCursorSelector = (state: ReduxState, key: string) => state.entries[key]
  ? state.entries[key].cursor.bottom
  : null

const bottomFetchStatusSelector = createSelector(
  [fetchStatusesSelector, bottomCursorSelector],
  (fetchStatuses, bottomCursor) => {
    return bottomCursor
      ? fetchStatuses[bottomCursor] || null
      : null;
  }
);

export default bottomFetchStatusSelector;