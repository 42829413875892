import { schema } from 'normalizr';

// 会員
const user = new schema.Entity('users');

// ニュース
const news = new schema.Entity('news');

// 個別メッセージ
const directMessage = new schema.Entity('directMessages');

// 店舗
const gym = new schema.Entity('gyms');

// 現在の利用状況
const currentUsageSituation = new schema.Entity('currentUsageSituations', {
  gym: gym
});

// 支払い履歴
const paymentHistory = new schema.Entity('paymentHistories');

const schemas = {
  user,
  news,
  directMessage,
  gym,
  currentUsageSituation,
  paymentHistory
};

export default schemas
