import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';

const useStyles = makeStyles({
  root: {
    fontSize: 20,
    margin: 0,
    padding: '20px 25px',
    color: '#ffffff',
    backgroundColor: '#002d52'
  }
});

interface Props {}

const PageTitle: React.FC<Props> = (props) => {
  const classes = useStyles();

  return (
    <>
      <h1 className={ classes.root }>
        { props.children }
      </h1>
    </>
  );
}

export default PageTitle;