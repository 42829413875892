import React from 'react';
import PrivateRoute from '../containers/common/PrivateRoute/PrivateRoute';
import GuestRoute from '../containers/common/GuestRoute/GuestRoute';
import Login from '../components/page/login/Login/Login';
import Top from '../components/page/top/Top/Top';
import UsageHistory from '../components/page/history/UsageHistory/UsageHistory';
import HowToUseMachine from '../components/page/howTo/HowToUseMachine/HowToUseMachine';
import ResetRequestForm from '../components/page/password/ResetRequestForm/ResetRequestForm';
import ResetForm from '../components/page/password/ResetForm/ResetForm';
import Settings from '../components/page/setting/Settings/Settings';
import PasswordSetting from '../components/page/setting/PasswordSetting/PasswordSetting';
import EmailSetting from '../components/page/setting/EmailSetting/EmailSetting';
import PaymentRoot from '../components/page/payment/PaymentRoot/PaymentRoot';
import CreditCardSetting from '../components/page/setting/CreditCardSetting/CreditCardSetting';
import MessageList from '../components/page/message/MessageList/MessageList';

const routes: JSX.Element[] = [
  // ログイン
  <GuestRoute exact key='/login' path='/login' component={ Login } />,

  // パスワードリセット - リクエスト
  <GuestRoute exact key='/password/email' path='/password/email' component={ ResetRequestForm } />,

  // パスワードリセット - リセット
  <GuestRoute exact key='/password/reset' path='/password/reset' component={ ResetForm } />,

  // トップページ
  <PrivateRoute exact key='/' path='/' component={ Top } />,

  // メッセージ
  <PrivateRoute exact key='/message' path='/message' component={ MessageList } />,

  // 利用履歴
  <PrivateRoute exact key='/history' path='/history' component={ UsageHistory } />,

  // マシンの利用方法
  <PrivateRoute exact key='/how/machine' path='/how/machine' component={ HowToUseMachine } />,

  // お支払い
  <PrivateRoute exact key='/payment' path='/payment' component={ PaymentRoot } />,

  /**
   * 設定
   */
  <PrivateRoute exact key='/settings' path='/settings' component={ Settings } />,

  // メールアドレスの変更
  <PrivateRoute exact key='/settings/email' path='/settings/email' component={ EmailSetting } />,

  // パスワードの変更
  <PrivateRoute exact key='/settings/password' path='/settings/password' component={ PasswordSetting } />,

  // お支払い履歴
  <PrivateRoute exact key='/settings/credit_card' path='/settings/credit_card' component={ CreditCardSetting } />,
];

export default routes;