import React from 'react';
import { connect } from 'react-redux';
import { ReduxState } from '../../../redux/reducers';
import { Dispatch } from 'redux';
import * as layoutActions from '../../../redux/actions/LayoutAction';
import * as sessionActions from '../../../redux/actions/SessionAction';
import { Drawer, List, makeStyles, Divider, Toolbar, IconButton } from '@material-ui/core';
import MenuItem from '../MenuItem/MenuItem';
import HomeIcon from '@material-ui/icons/Home';
import HistoryIcon from '@material-ui/icons/History';
import VideoLibraryIcon from '@material-ui/icons/VideoLibrary';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import SettingsIcon from '@material-ui/icons/Settings';
import PaymentIcon from '@material-ui/icons/Payment';
import CloseIcon from '@material-ui/icons/Close';
import NotificationsIcon from '@material-ui/icons/Notifications';

const useStyles = makeStyles({
  list: {
    width: 250
  },
  toolbar: {
    height: 60,
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)'
  },
  close: {
    marginLeft: 'auto'
  }
});

interface Props {
  open: boolean;
  onClose: () => void;
  onLogout: () => void;
}

const MainMenu: React.FC<Props> = (props) => {
  const classes = useStyles();

  return (
    <>
      <Drawer
        anchor='left'
        open={ props.open }
        onClose={ props.onClose }
      >
        <Toolbar className={ classes.toolbar }>
          <IconButton edge='end' className={ classes.close } onClick={ props.onClose }>
            <CloseIcon />
          </IconButton>
        </Toolbar>
        <List className={ classes.list } onClick={ props.onClose }>
          <MenuItem to='/' text='ホーム' icon={ <HomeIcon /> } />
          <MenuItem to='/message' text='個別メッセージ' icon={ <NotificationsIcon /> } />
          <MenuItem to='/history' text='利用履歴' icon={ <HistoryIcon /> } />
          <MenuItem to='/how/machine' text='マシンの利用方法' icon={ <VideoLibraryIcon /> } />
        </List>
        <Divider />
        <List onClick={ props.onClose }>
          <MenuItem to='/payment' text='お支払い' icon={ <PaymentIcon /> } />
          <MenuItem to='/settings' text='設定' icon={ <SettingsIcon /> } />
          {/* <MenuItem to='/about' text='このアプリについて' icon={ <HelpIcon /> } /> */}
          <MenuItem text='ログアウト' icon={ <ExitToAppIcon /> } onClick={ props.onLogout } />
        </List>
      </Drawer>
    </>
  );
}

const mapStateToProps = (state: ReduxState) => ({
  open: state.layout.menuIsActive
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  onClose: () => dispatch(layoutActions.closeMenu()),
  onLogout: () => dispatch(sessionActions.logout.started({}))
});

export default connect(mapStateToProps, mapDispatchToProps)(MainMenu);