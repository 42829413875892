import { Epic, combineEpics } from 'redux-observable';
import { filter, switchMap, mergeMap, catchError, takeUntil } from 'rxjs/operators';
import * as directMessageActions from '../../actions/DirectMessageAction';
import * as entitiesActions from '../../actions/EntityAction';
import * as sessionActions from '../../actions/SessionAction';
import request, { handleError } from '../../../utils/function/request';
import { normalize } from 'normalizr';
import schemas from '../../schemas';
import { of } from 'rxjs';
import { DirectMessageRes } from '../../../utils/interface/DirectMessageRes';

/**
 * 最新を取得
 * 
 * @param action$ 
 */
const fetchLatestEpic: Epic = action$ => action$.pipe(
  filter(directMessageActions.fetchDirectMessagesLatest.started.match),
  switchMap(({payload}) => request<DirectMessageRes>({
    url: '/v1/direct_messages'
  }).pipe(
    mergeMap(res => {
      const {data, unread_direct_messages_count, ...metas} = res.data;
      const normalizedData = normalize(data, [schemas.directMessage]);

      return of(
        entitiesActions.update(normalizedData.entities),
        sessionActions.fetchUnreadCount.done({params: {}, result: unread_direct_messages_count}),
        directMessageActions.fetchDirectMessagesLatest.done({params: payload, result: {
          entries: normalizedData.result,
          ...metas
        }})
      );
    }),
    catchError(error => handleError(error, directMessageActions.fetchDirectMessagesLatest.failed({
      params: payload,
      error
    }))),
    takeUntil(action$.pipe(
      filter(directMessageActions.cancelFetchingDirectMessages.match)
    ))
  ))
);

/**
 * 更新を取得
 * 
 * @param action$ 
 */
const fetchUpdatesEpic: Epic = action$ => action$.pipe(
  filter(directMessageActions.fetchDirectMessagesUpdates.started.match),
  switchMap(({payload}) => request<DirectMessageRes>({
    url: '/v1/direct_messages',
    params: {top_cursor: payload}
  }).pipe(
    mergeMap(res => {
      const {data, unread_direct_messages_count, ...metas} = res.data;
      const normalizedData = normalize(data, [schemas.directMessage]);

      return of(
        entitiesActions.update(normalizedData.entities),
        sessionActions.fetchUnreadCount.done({params: {}, result: unread_direct_messages_count}),
        directMessageActions.fetchDirectMessagesUpdates.done({params: payload, result: {
          entries: normalizedData.result,
          ...metas
        }})
      );
    }),
    catchError(error => handleError(error, directMessageActions.fetchDirectMessagesUpdates.failed({
      params: payload,
      error
    }))),
    takeUntil(action$.pipe(
      filter(directMessageActions.cancelFetchingDirectMessages.match)
    ))
  ))
);

/**
 * さらに取得
 * 
 * @param action$ 
 */
const fetchMoreEpic: Epic = action$ => action$.pipe(
  filter(directMessageActions.fetchDirectMessagesMore.started.match),
  switchMap(({payload}) => request<DirectMessageRes>({
    url: '/v1/direct_messages',
    params: {bottom_cursor: payload}
  }).pipe(
    mergeMap(res => {
      const {data, unread_direct_messages_count, ...metas} = res.data;
      const normalizedData = normalize(data, [schemas.directMessage]);

      return of(
        entitiesActions.update(normalizedData.entities),
        sessionActions.fetchUnreadCount.done({params: {}, result: unread_direct_messages_count}),
        directMessageActions.fetchDirectMessagesMore.done({params: payload, result: {
          entries: normalizedData.result,
          ...metas
        }})
      );
    }),
    catchError(error => handleError(error, directMessageActions.fetchDirectMessagesMore.failed({
      params: payload,
      error
    }))),
    takeUntil(action$.pipe(
      filter(directMessageActions.cancelFetchingDirectMessages.match)
    ))
  ))
);

const directMessageEpic = combineEpics(
  fetchLatestEpic,
  fetchUpdatesEpic,
  fetchMoreEpic,
);

export default directMessageEpic;