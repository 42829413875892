import React from 'react';
import { makeStyles, FormLabelProps, FormLabel, FormHelperText } from '@material-ui/core';

const useStyles = makeStyles({
  root: {
    marginBottom: 25
  },
  label: {
    display: 'inline-block',
    marginBottom: 8,
    fontSize: 13,
    color: '#4d4d4d',
    fontWeight: 600,
  }
});

interface Props extends FormLabelProps {
  label: string;
  helperText?: string;
}

const FormItem: React.FC<Props> = ({label, helperText, children, className, ...formLabelProps}) => {

  const classes = useStyles();

  return (
    <>
      <div className={ classes.root }>
        <FormLabel className={ `${ classes.label } ${ className }` } {...formLabelProps}>{ label }</FormLabel>
        <div>{ children }</div>
        {(formLabelProps.error && helperText) && (
          <div>
            <FormHelperText error>{ helperText }</FormHelperText>
          </div>
        )}
      </div>
    </>
  );
}

export default FormItem;
