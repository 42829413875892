import React from 'react';

interface Props {}

const RunningIcon: React.FC<Props> = (props) => {
  return (
    <>
      <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 30 30"><g transform="translate(-434 -140)"><rect width="30" height="30" transform="translate(434 140)" fill="none"/><g transform="translate(436 140)"><path d="M31.9,155.237c.06-.005.12-.008.18-.008.043,0,.086,0,.129,0l1.807.117,4.138-12.062,1.021,1.5L40.426,144l-4.055-6.962-1.724,1.086,2.075,3.054Z" transform="translate(-30.221 -129.827)" fill="#fff"/><g transform="translate(0 26.474)"><path d="M26.522,505.671a.861.861,0,0,1-.858.858H.612a.562.562,0,0,1-.564-.806l.719-1.967a1.219,1.219,0,0,1,1.151-.751l23.747,1.537a.931.931,0,0,1,.856.914Z" transform="translate(0.001 -503.004)" fill="#fff"/></g><path d="M232.17,166.671l-1.743-.369a1.038,1.038,0,0,0-.563,1.993l2.305.82Z" transform="translate(-217.112 -157.527)" fill="#fff"/><g transform="translate(15.502 0)"><ellipse cx="2.094" cy="2.094" rx="2.094" ry="2.094" fill="#fff"/></g><path d="M230.059,103.725l-4.723-.256-.667-5.17c-.007-.056-.018-.115-.031-.172,0-.02-.01-.039-.015-.059v-.19l-.282.29a1.491,1.491,0,0,1-2.3-1.884l1.875-2.729-1.392-1.2.419-.488,1.829,1.573-2.2,3.206c-.014.021-.028.042-.04.064a.844.844,0,0,0,.094.965v0c.017.019.035.038.053.057a.788.788,0,0,0,.064.056l.021.015c.015.012.031.023.047.033l.025.015.047.027.026.012.048.022.027.009.05.017.03.007.049.011.035.005.045.006.04,0,.035,0h.006l.044,0,.041,0,.026,0a.838.838,0,0,0,.491-.248l.743-.765h0l2.843-2.925c.016-.016.034-.036.048-.053a1.176,1.176,0,0,0-.125-1.659l-.078-.067s-3.349-2.936-3.589-3.086a2.783,2.783,0,0,0-1.3-.3,2.2,2.2,0,0,0-2.2,2.2v6.566a1.8,1.8,0,0,0-.093.193l-2.519,6.12-.058.137-1.623,5.422a1.018,1.018,0,0,0,1.91.694l2.236-5.2-.058.137,1.818-3.117.717,3.133.045.2a1.346,1.346,0,0,0,1.466,1.03l6.054-.706a.974.974,0,0,0-.058-1.941Z" transform="translate(-204.523 -84.183)" fill="#fff"/></g></g></svg>
    </>
  );
}

export default RunningIcon;