import React from 'react';
import Headline from '../../../common/Headline/Headline';
import CurrentUsageSituation from '../CurrentUsageSituation/CurrentUsageSituation';
import { makeStyles } from '@material-ui/core';
import NewsList from '../NewsList/NewsList';

const useStyles = makeStyles({
  content: {
    paddingTop: 40,
    paddingBottom: 40,
  }
});

interface Props {}

const Top: React.FC<Props> = (props) => {
  const classes = useStyles();
  return (
    <>
      <CurrentUsageSituation />
      <div className={ classes.content }>  
        <Headline>FITSHAPEからのご連絡</Headline>
        <NewsList />
      </div>
    </>
  );
}

export default Top;