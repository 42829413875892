import React from 'react';

interface Props {}

const BenchPressIcon: React.FC<Props> = (props) => {
  return (
    <>
      <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 30 30"><g transform="translate(-434 -178)"><rect width="30" height="30" transform="translate(434 178)" fill="none"/><g transform="translate(435 178)"><path d="M0,328.92v12.688H2.771v-8.645L14.7,337.578h5.478v4.031H22.95v-6.8H15.217Z" transform="translate(0 -311.608)" fill="#fff"/><ellipse cx="2.566" cy="2.566" rx="2.566" ry="2.566" transform="translate(1.138 12.191)" fill="#fff"/><path d="M149.636,173.5l-1.782-8.483a1.551,1.551,0,0,0-1.43-1.223l-7.779-.427c-.087-.063-5.431-2.817-5.431-2.817l1.951-2.164a1.583,1.583,0,0,0,.237-1.764l-1.628-3.255A5.349,5.349,0,0,1,131,154.735l.9,2.17s-3.71,3.555-3.719,3.566a1.812,1.812,0,0,0-.459,1.587,3,3,0,0,0,1.644,2.3l7.221,2.794a3.171,3.171,0,0,0,1.394.247h6.816l1.974,6.84a1.476,1.476,0,0,0,2.857-.731Z" transform="translate(-120.99 -145.298)" fill="#fff"/><path d="M98.927,8.295A4.147,4.147,0,1,0,94.78,4.147,4.147,4.147,0,0,0,98.927,8.295Zm0-5.192a1.045,1.045,0,1,1-1.045,1.045A1.045,1.045,0,0,1,98.927,3.1Z" transform="translate(-89.792)" fill="#fff"/></g></g></svg>
    </>
  );
}

export default BenchPressIcon;