import React from 'react';
import { ReduxState } from '../../../redux/reducers';
import { Dispatch } from 'redux';
import * as sessionActions from '../../../redux/actions/SessionAction';
import { connect } from 'react-redux';
import { makeStyles, IconButton } from '@material-ui/core';
import NotificationsIcon from '@material-ui/icons/NotificationsOutlined';
import { Link } from 'react-router-dom';
import { interval } from 'rxjs';

const useStyles = makeStyles({
  root: {
    display: 'inline-block',
    position: 'relative',
    zIndex: 0
  },
  unreadCount: {
    position: 'absolute',
    right: -5,
    top: 5,
    backgroundColor: 'red',
    lineHeight: 1,
    color: '#ffffff',
    fontWeight: 600,
    fontSize: 10,
    minWidth: 18,
    height: 18,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 10,
    zIndex: 100,
    padding: 3,
    boxSizing: 'border-box',
    userSelect: 'none'
  }
});

interface Props {
  unread_count: number;
  loadUnreadCount: () => void;
  cancelLoading: () => void;
}

const NotificationButton: React.FC<Props> = (props) => {
  const classes = useStyles();

  React.useEffect(() => {
    const subscription = interval(1000 * 60 * 3).subscribe(
      () => props.loadUnreadCount()
    );

    return () => {
      props.cancelLoading();
      subscription.unsubscribe();
    }
  }, []);

  return (
    <>
      <div className={ classes.root }>
        {props.unread_count > 0 && (
          <div className={ classes.unreadCount }>{ props.unread_count }</div>
        )}
        <IconButton edge='end' component={ Link } to='/message'>
          <NotificationsIcon />
        </IconButton>
      </div>
    </>
  );
}

const mapStateToProps = (state: ReduxState) => ({
  unread_count: state.session.unread_direct_messages_count,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  loadUnreadCount: () => dispatch(sessionActions.fetchUnreadCount.started({})),
  cancelLoading: () => dispatch(sessionActions.cancelFetchingUnreadCount())
});

export default connect(mapStateToProps, mapDispatchToProps)(NotificationButton);