import { Epic, combineEpics } from 'redux-observable';
import { filter, switchMap, mergeMap, catchError, takeUntil } from 'rxjs/operators';
import * as newsActions from '../../actions/NewsAction';
import * as entitiesActions from '../../actions/EntityAction';
import request, { handleError } from '../../../utils/function/request';
import { PaginateRes } from '../../../utils/interface/PaginateRes';
import { News } from '../../../utils/interface/News';
import { normalize } from 'normalizr';
import schemas from '../../schemas';
import { of } from 'rxjs';

/**
 * 最新を取得
 * 
 * @param action$ 
 */
const fetchLatestEpic: Epic = action$ => action$.pipe(
  filter(newsActions.fetchNewsListLatest.started.match),
  switchMap(({payload}) => request<PaginateRes<News>>({
    url: '/v1/news'
  }).pipe(
    mergeMap(res => {
      const {data, ...metas} = res.data;
      const normalizedData = normalize(data, [schemas.news]);

      return of(
        entitiesActions.update(normalizedData.entities),
        newsActions.fetchNewsListLatest.done({params: payload, result: {
          entries: normalizedData.result,
          ...metas
        }})
      );
    }),
    catchError(error => handleError(error, newsActions.fetchNewsListLatest.failed({
      params: payload,
      error
    }))),
    takeUntil(action$.pipe(
      filter(newsActions.cancelFetchingNewsList.match)
    ))
  ))
);

/**
 * 更新を取得
 * 
 * @param action$ 
 */
const fetchUpdatesEpic: Epic = action$ => action$.pipe(
  filter(newsActions.fetchNewsListUpdates.started.match),
  switchMap(({payload}) => request<PaginateRes<News>>({
    url: '/v1/news',
    params: {top_cursor: payload}
  }).pipe(
    mergeMap(res => {
      const {data, ...metas} = res.data;
      const normalizedData = normalize(data, [schemas.news]);

      return of(
        entitiesActions.update(normalizedData.entities),
        newsActions.fetchNewsListUpdates.done({params: payload, result: {
          entries: normalizedData.result,
          ...metas
        }})
      );
    }),
    catchError(error => handleError(error, newsActions.fetchNewsListUpdates.failed({
      params: payload,
      error
    }))),
    takeUntil(action$.pipe(
      filter(newsActions.cancelFetchingNewsList.match)
    ))
  ))
);

/**
 * さらに取得
 * 
 * @param action$ 
 */
const fetchMoreEpic: Epic = action$ => action$.pipe(
  filter(newsActions.fetchNewsListMore.started.match),
  switchMap(({payload}) => request<PaginateRes<News>>({
    url: '/v1/news',
    params: {bottom_cursor: payload}
  }).pipe(
    mergeMap(res => {
      const {data, ...metas} = res.data;
      const normalizedData = normalize(data, [schemas.news]);

      return of(
        entitiesActions.update(normalizedData.entities),
        newsActions.fetchNewsListMore.done({params: payload, result: {
          entries: normalizedData.result,
          ...metas
        }})
      );
    }),
    catchError(error => handleError(error, newsActions.fetchNewsListMore.failed({
      params: payload,
      error
    }))),
    takeUntil(action$.pipe(
      filter(newsActions.cancelFetchingNewsList.match)
    ))
  ))
);

const newsEpic = combineEpics(
  fetchLatestEpic,
  fetchUpdatesEpic,
  fetchMoreEpic
);

export default newsEpic;