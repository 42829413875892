import { reducerWithoutInitialState } from 'typescript-fsa-reducers';
import { EntryReducerState } from '..';
import newsAllReducer, { newsStateKey } from './newsReducer';

const newsReducer = reducerWithoutInitialState<EntryReducerState>()

.default((state, action) => ({
  ...state,
  [newsStateKey]: newsAllReducer(state[newsStateKey], action),
}));

export default newsReducer;