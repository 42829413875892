import React from 'react';
import MenuIcon from '@material-ui/icons/Menu';
import { IconButton } from '@material-ui/core';
import { Dispatch } from 'redux';
import * as layoutActions from '../../../redux/actions/LayoutAction';
import { connect } from 'react-redux';

interface Props {
  edge?: false | 'start' | 'end';
  onClick?: () => void;
}

const MenuButton: React.FC<Props> = (props) => {

  const handleClick = () => {
    if (props.onClick) {
      props.onClick();
    }
  }

  return (
    <>
      <IconButton edge={ props.edge } onClick={ handleClick }>
        <MenuIcon />
      </IconButton>
    </>
  );
}

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  onClick: () => dispatch(layoutActions.openMenu())
});

export default connect(mapStateToProps, mapDispatchToProps)(MenuButton);