import { combineReducers } from 'redux';
import { FetchStatus } from '../../../utils/type/FetchStatus';
import { UserEntity } from '../../../utils/interface/Entity/UserEntity';
import { NewsEntity } from '../../../utils/interface/Entity/NewsEntity';
import { AxiosError } from 'axios';
import userEntitiesReducer from './userEntitiesReducer';
import newsEntitiesReducer from './newsEntitiesReducer';
import { GymEntity } from '../../../utils/interface/Entity/GymEntity';
import gymEntitiesReducer from './gymEntitiesReducer';
import { PaymentHistoryEntity } from '../../../utils/interface/Entity/PaymentHistoryEntity';
import paymentHistoryEntitiesReducer from './paymentHistoryReducer';
import { DirectMessageEntity } from '../../../utils/interface/Entity/DirectMessageEntity';
import directMessageEntitiesReducer from './directMessageEntitiesReducer';

export interface Entity<T> {
  entities: {[key: number]: T};
  fetchStatus: {[key: number]: FetchStatus};
  error: {[key: number]: AxiosError};
};

export interface EntitiesPayload {
  users?: {[key: number]: UserEntity};
  news?: {[key: number]: NewsEntity};
  directMessages?: {[key: number]: DirectMessageEntity}
  gyms?: {[key: number]: GymEntity};
  paymentHistories?: {[key: number]: PaymentHistoryEntity}
}

export interface EntitiesState {
  users: Entity<UserEntity>;
  news: Entity<NewsEntity>;
  directMessages: Entity<DirectMessageEntity>;
  gyms: Entity<GymEntity>;
  paymentHistories: Entity<PaymentHistoryEntity>;
}

const entitiesReducer = combineReducers<EntitiesState>({
  users: userEntitiesReducer,
  news: newsEntitiesReducer,
  directMessages: directMessageEntitiesReducer,
  gyms: gymEntitiesReducer,
  paymentHistories: paymentHistoryEntitiesReducer,
})

export default entitiesReducer;