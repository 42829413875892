import React from 'react';
import { makeStyles } from '@material-ui/core';
import Header from '../Header/Header';
import { ReduxState } from '../../../redux/reducers';
import { connect } from 'react-redux';
import MainMenu from '../MainMenu/MainMenu';
import Container from '../../common/Container/Container';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    flexGrow: 1,
    paddingTop: 60,
  },
  main: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    flexGrow: 1,
  }
});

interface Props {
  isAuthenticated: boolean;
}

const AppShell: React.FC<Props> = (props) => {
  const classes = useStyles();

  return (
    <>
      {props.isAuthenticated
        ? (
          <div className={ classes.root }>
            <Header />
            <MainMenu />
            <main className={ classes.main }>
              <Container className={ classes.main }>{ props.children }</Container>
            </main>
          </div>
        )
        : (<main>{ props.children }</main>)
      }
    </>
  );
}

const mapStateToProps = (state: ReduxState) => ({
  isAuthenticated: state.session.userId !== undefined
});

export default connect(mapStateToProps)(AppShell);