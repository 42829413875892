import { reducerWithInitialState } from 'typescript-fsa-reducers';
import moment, { Moment } from 'moment';
import * as usageHistoryActions from '../../actions/UsageHistoryAction';
import { FetchStatus } from '../../../utils/type/FetchStatus';
import { UsageHistory } from '../../../utils/interface/UsageHistory';

export interface UsageHistoryState {
  fetchStatus: FetchStatus;
  lastFetchTimestamp: Moment | null;
  total_minutes: number;
  histories: UsageHistory[];
}

const initialState: UsageHistoryState = {
  fetchStatus: 'loading',
  lastFetchTimestamp: null,
  total_minutes: 0,
  histories: [],
};

export interface UsageHistoryReducerState {
  [key: string]: UsageHistoryState;
}

export const usageHistoryStateKey = (date: Moment) => date.format('YYYY-MM');

const usageHistoryReducer = reducerWithInitialState<UsageHistoryReducerState>({})

// 利用履歴の取得 - 開始
.case(usageHistoryActions.fetchUsageHistoryData.started, (state, payload) => ({
  ...state,
  [usageHistoryStateKey(payload)]: initialState
}))

// 利用履歴の取得 - 成功
.case(usageHistoryActions.fetchUsageHistoryData.done, (state, {params, result}) => {
  const key = usageHistoryStateKey(params);

  return {
    ...state,
    [key]: {
      ...state[key],
      fetchStatus: 'loaded',
      lastFetchTimestamp: moment(),
      ...result,
    }
  };
})

// 利用履歴の取得 - 失敗
.case(usageHistoryActions.fetchUsageHistoryData.failed, (state, {params, error}) => {
  const key = usageHistoryStateKey(params);

  return {
    ...state,
    [key]: {
      ...state[key],
      fetchStatus: 'failed',
      lastFetchTimestamp: moment()
    }
  }
})

export default usageHistoryReducer;