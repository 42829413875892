import React from 'react';
import { CircularProgress } from '@material-ui/core';
import styles from './Progress.module.scss';

interface ProgressProps {}

const Progress: React.FC<ProgressProps> = (props) => {
  return (
    <div className={ styles.progressContainer }>
      <CircularProgress  size={30} color='primary' />
    </div>
  );
}

export default Progress;