export const DirectMessageActionType = {
  // 個別メッセージを取得
  FETCH_DIRECT_MESSAGE: 'FETCH_DIRECT_MESSAGE',

  // 個別メッセージの取得をキャンセル
  FETCH_DIRECT_MESSAGE_CANCELLED: 'FETCH_DIRECT_MESSAGE_CANCELLED',

  // 最新の個別メッセージを取得
  FETCH_DIRECT_MESSAGES_LATEST: 'FETCH_DIRECT_MESSAGES_LATEST',

  // 個別メッセージの更新を取得
  FETCH_DIRECT_MESSAGES_UPDATES: 'FETCH_DIRECT_MESSAGES_UPDATES',

  // 個別メッセージをさらに取得
  FETCH_DIRECT_MESSAGES_MORE: 'FETCH_DIRECT_MESSAGES_MORE',

  // 個別メッセージリストの取得をキャンセル
  FETCH_DIRECT_MESSAGES_CANCELLED: 'FETCH_DIRECT_MESSAGES_CANCELLED',
};