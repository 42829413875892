export interface Config {
  API: string | undefined;
}

const Config: Config = process.env.NODE_ENV === 'production'
  ? {
      API: process.env.REACT_APP_PRODUCTION_API_HOST,
    }
  : {
      API: process.env.REACT_APP_DEV_API_HOST,
    };

export default Config;
