import { ReduxState } from '../../reducers';
import { createSelector } from 'reselect';

const fetchStatusesSelector = (state: ReduxState, key: string) => state.entries[key]
  ? state.entries[key].fetchStatus
  : {};
const topCursorSelector = (state: ReduxState, key: string) => state.entries[key]
  ? state.entries[key].cursor.top
  : null

const topFetchStatusSelector = createSelector(
  [fetchStatusesSelector, topCursorSelector],
  (fetchStatuses, topCursor) => {
    return topCursor
      ? fetchStatuses[topCursor] || null
      : null;
  }
);

export default topFetchStatusSelector;