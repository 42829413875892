import React from 'react';
import * as H from 'history';
import { Route, Redirect, RouteComponentProps, RouteChildrenProps } from 'react-router';
import { ReduxState } from '../../../redux/reducers';
import { connect } from 'react-redux';
import { History } from 'history';
import { User } from '../../../utils/interface/User';
import authUserSelector from '../../../redux/selectors/user/authUserSelector';

export interface PrivateRouteComponentProps<T = {}> extends RouteComponentProps<T> {
  authUser: User;
}

interface PrivateRouteProps {
  authUser: User | null;
  component?: React.ComponentType<PrivateRouteComponentProps<any>> | React.ComponentType<any>;
  render?: ((props: PrivateRouteComponentProps<any>) => React.ReactNode);
  redirectTo?: History.LocationDescriptor<any>;
  location?: H.Location;
  children?: ((props: RouteChildrenProps<any>) => React.ReactNode) | React.ReactNode;
  path?: string | string[];
  exact?: boolean;
  sensitive?: boolean;
  strict?: boolean;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({component: Component, render, authUser, redirectTo, ...rest}) => {
  return render ? (
    <Route {...rest} render={props => authUser
      ? (render)({...props, authUser})
      : () => <Redirect to={ redirectTo ? redirectTo : '/login'} />
    }/>
  ) : (
    <Route {...rest} render={props => authUser && Component !== undefined
      ? <Component {...props} authUser={ authUser } />
      : <Redirect to={ redirectTo ? redirectTo : '/login'} />
    } />
  );
}

const mapStateToProps = (state: ReduxState) => ({
  authUser: authUserSelector(state)
});

export default connect(mapStateToProps)(PrivateRoute);
