import React from 'react';
import { UsageHistory } from '../../../../utils/interface/UsageHistory';
import moment from 'moment';
import { makeStyles, Table, TableHead, TableRow, TableCell, TableBody } from '@material-ui/core';

const useStyles = makeStyles({
  table: {
    width: '100%'
  },
  th: {
    color: '#ffffff',
    fontSize: 14,
    backgroundColor: '#585858',
    fontWeight: 600,
    border: '1px solid #707070'
  },
  td: {
    color: '#000000',
    border: '1px solid #707070'
  }
});

interface Props {
  histories: UsageHistory[];
}

const HistoryTable: React.FC<Props> = (props) => {
  const classes = useStyles();

  return (
    <>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell size='small' align='center' className={ classes.th }>日付</TableCell>
            <TableCell size='small' align='center' className={ classes.th }>IN</TableCell>
            <TableCell size='small' align='center' className={ classes.th }>OUT</TableCell>
            <TableCell size='small' align='center' className={ classes.th }>滞在時間</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.histories.map(history => (
            <TableRow key={ history.id }>
              <TableCell size='small' align='center' className={ classes.td }>{ moment(history.date).format('MM/DD') }</TableCell>
              <TableCell size='small' align='center' className={ classes.td }>{ moment(history.in).format('HH:mm') }</TableCell>
              <TableCell size='small' align='center' className={ classes.td }>{ moment(history.out).format('HH:mm') }</TableCell>
              <TableCell size='small' align='center' className={ classes.td }>{ history.diff_in_minutes }分</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </>
  );
}

export default HistoryTable;