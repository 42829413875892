import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  root: {
    fontSize: 16,
    textAlign: 'center',
    margin: 0,
    padding: 0,
    paddingBottom: 20
  },
  text: {
    minWidth: 200,
    color: '#ffffff',
    fontWeight: 600,
    display: 'inline-block',
    padding: '5px 25px',
    backgroundColor: '#313131',
    borderRadius: '15px / 50%',
    boxSizing: 'border-box'
  }
});

interface Props {}

const Headline: React.FC<Props> = (props) => {
  const classes = useStyles();

  return (
    <>
      <h2 className={ classes.root }>
        <div className={ classes.text }>
          { props.children }
        </div>
      </h2>
    </>
  );
}

export default Headline;